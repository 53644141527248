import React, { useState } from 'react'
import CustomTextField from '../components/globals/CustomTextField'
import { FaEnvelope, FaLock } from 'react-icons/fa'
import { Container, Row, Col } from 'react-bootstrap';
import logo from '../assets/images/logo.gif';
import sharkskillz from '../assets/images/sharkskillz.png';
import { Link, useNavigate } from 'react-router-dom';
import CustomPasswordField from '../components/globals/CustomPasswordField';
import CustomPrimaryButton from '../components/globals/CustomPrimaryButton';
import CustomSocialButton from '../components/globals/CustomSocialButton';
import google from '../assets/images/google.png'
import facebook from '../assets/images/facebook.png'
import { useForm, FormProvider } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import { userAtom, tokenAtom, roleAtom } from "../store/authAtom"
import { snakeBarState, isLoaderState } from "../store/RecoilStore"
import { setRoleLocal, setTokenLocal, setUserLocal } from '../utils/Session';
import { apiPostWithoutAuth } from '../utils/Api';
import { ENDPOINTS } from '../utils/EndPoint';


function Login() {

  const [urlParam, setUrlParam] = useState(window.location.pathname.split('/').pop(),);
  const navigate = useNavigate();

  const [user, setUser] = useRecoilState(userAtom);
  const [role, setRole] = useRecoilState(roleAtom);
  const [token, setToken] = useRecoilState(tokenAtom);
  const [snackBarInfo, setSnackBarInfo] = useRecoilState(snakeBarState);
  const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);


  const [errMsg, setErrMsg] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);


  const methods = useForm();

  const onSubmit = (data) => {

    setBtnLoading(true)
    setIsLoaderInfo(true)
    apiPostWithoutAuth(
      ENDPOINTS.UserLogin,
      data,
      (res) => {
        setUser(res.data.user_details)
        setToken(res.data.token)
        setUserLocal(res.data.user_details)
        setTokenLocal(res.data.token)

        //Role
        setRoleLocal(res.data.user_details.roles[0]['name'])
        setRole(res.data.user_details.roles[0]['name'])

        setIsLoaderInfo(false)
        setSnackBarInfo({
          snackStatus: true,
          snackColor: "bg-primary",
          snackMsg: "login successfully",
        });
        if (res.data.user_details.roles[0]['name'] === 'admin') {
          navigate("/admin/dashboard");
        } else {
          if (res.data.user_details.email_verified_at) {
            if (res.data.user_details.is_topic) {
              navigate("/admin/ocean");
            } else {
              navigate("/welcome");
            }
          } else {
            navigate("/verify");
          }
        }
        setBtnLoading(false)
      },
      (err) => {
        console.log("err", err)
        setSnackBarInfo({
          snackStatus: true,
          snackColor: "bg-danger",
          snackMsg: err.data,
        });
        setErrMsg(err.data)
        setBtnLoading(false)
        setIsLoaderInfo(false)
      }
    );


  };


  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Container fluid className='p-0'>
          <Row className='g-0'>
            <Col md={12} lg={7}>
              <div className='join-shark'>
                <div className='inner-logo d-flex align-items-center pt-3'>
                  <img src={logo} className='register-logo' />
                  <img src={sharkskillz} className='logo-pic' />
                </div>
                <div className='inner-text'>
                  <h2 className='text-white font-55'>
                    Join SharkSkillz and unlock<br />
                    your potential.
                  </h2>
                  <p className='text-white font-22 skranji-regular mt-4'>
                    It is a long established fact that a reader will be distracted by the readable
                    content of a page when looking at its layout. The point of using Lorem Ipsum is
                    that it has a more-or-less normal distribution of letters, as opposed to using
                    'Content here, content here', making it look like readable English.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={12} lg={5} className='register d-flex'>
              <div className='d-block mx-auto text-center align-self-center register-inner'>
                <h1 className='skranji-bold text-white'>Login</h1>
                <h6 className='text-white mb-4 font-22'>The faster you fill up, the faster you start learning.</h6>

                <CustomTextField
                  icon={<FaEnvelope />}
                  label="Email"
                  placeholder="chriswayne@gmail.com"
                  name="email"
                  type="email"
                  classNamePro="mb-2"
                  isRequired={true}
                  validation={{
                    required: "This field is required",
                    pattern: {
                      value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                      message: "Please enter valid email",
                    },
                  }}
                />

                <CustomPasswordField
                  label="Password"
                  placeholder="************"
                  name="password"
                  type="password"
                  icon={<FaLock />}
                  classNamePro="mb-2"
                  isRequired={true}
                  validation={{
                    required: "This field is required"
                  }}
                />


                <div className='d-flex gap-2 mt-3 mb-3 align-items-center justify-content-between'>
                  {/* <CustomCheckBox
                    id="registercheckbox"
                    // value="check"
                    name="is_agreed"
                    isRequired={true}
                    validation={{
                      required: "Required"
                    }}
                  /> */}
                  <Link to="/forget-password" className='text-warning skranji-bold text-decoration-none'> Forgot Password?</Link>

                </div>

                {errMsg && <span className="error-message">{errMsg}</span>}

                <div className='mt-2'>
                  <CustomPrimaryButton
                    btnTextPro="Login"
                    btnLoading={btnLoading}
                  // handleOnClick={() => {
                  //   navigate('/admin/ocean');
                  // }}
                  />
                </div>

                <p className='mt-2 text-white or-line'>OR</p>

                <CustomSocialButton
                  btnTextPro="Continue with Google"
                  classNamePro="mb-4"
                  icon={google}
                  handleOnClick={() => {
                    navigate('/register-form');
                  }}
                />

                <CustomSocialButton
                  btnTextPro="Continue with Facebook"
                  classNamePro="mb-4"
                  icon={facebook}
                  handleOnClick={() => {
                    navigate('/register-form');
                  }}
                />

                <p className='mt-2 text-white'>Don’t have an account? <Link to="/register" className='text-warning'> Sign up!</Link></p>


              </div>
            </Col>
          </Row>
        </Container>
      </form>
    </FormProvider>
  )
}

export default Login