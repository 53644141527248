import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import CustomWelcomeBox from '../components/globals/CustomWelcomeBox'
import QuestionItem from '../components/globals/QuestionItem'
import CustomPrimaryButton from '../components/globals/CustomPrimaryButton'
import ProgressBar from 'react-bootstrap/ProgressBar'
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { snakeBarState, isLoaderState } from "../store/RecoilStore"
import { apiGetAuth, apiPost } from '../utils/Api';
import { ENDPOINTS } from '../utils/EndPoint';
import { useRecoilState } from 'recoil'



function Welcome() {

    const [stepForm, setStepForm] = useState(0);
    const [topics, setTopics] = useState(null);

    const [selectedAnswers, setSelectedAnswers] = useState([]);
    const [snackBarInfo, setSnackBarInfo] = useRecoilState(snakeBarState);
    const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);


    const [errMsg, setErrMsg] = useState("");
    const [btnLoading, setBtnLoading] = useState(false);


    const totalSteps = topics?.length;


    const handlePrevious = () => {
        if (stepForm > 0) {
            setStepForm(stepForm - 1);
        }
    };

    const navigate = useNavigate();

    // if (stepForm === 4) {
    //     navigate("/admin/ocean");
    // }

    useEffect(() => {
        setIsLoaderInfo(true)
        apiGetAuth(
            ENDPOINTS.GetTopic,
            (res) => {
                setTopics(res)
                setIsLoaderInfo(false)

            },
            (err) => {
                setIsLoaderInfo(false)
                console.log("err", err)
                setErrMsg(err.data)
                setSnackBarInfo({
                    snackStatus: true,
                    snackColor: "bg-danger",
                    snackMsg: err.data,
                });
            }
        );
    }, [])



    const handleAnswerSelect = (answerId, questionId) => {

        const answerIndex = selectedAnswers.findIndex(item => item.question_id === questionId);

        if (answerIndex !== -1) {
            const newSelectedAnswers = [...selectedAnswers];
            const answerIndexInSelected = newSelectedAnswers[answerIndex].answer.indexOf(answerId);

            if (answerIndexInSelected !== -1) {
                newSelectedAnswers[answerIndex].answer.splice(answerIndexInSelected, 1);
            } else {
                newSelectedAnswers[answerIndex].answer.push(answerId);
            }

            setSelectedAnswers(newSelectedAnswers.filter(item => item.answer.length > 0));
        } else {
            setSelectedAnswers([...selectedAnswers, { question_id: questionId, answer: [answerId] }]);
        }
    };



    const handleTopicsSend = () => {

        if (stepForm == totalSteps) {

            if (selectedAnswers.length > 0) {

                const formdata = new FormData();

                selectedAnswers.forEach((item, i) => {
                    formdata.append(`question_id[${i}]`, item.question_id);
                    item.answer.forEach((item2, i2) => {
                        formdata.append(`answer[${i}][${i2}]`, item2);
                    })
                })

                setBtnLoading(true)
                setIsLoaderInfo(true)
                apiPost(
                    ENDPOINTS.AddTopic,
                    formdata,
                    (res) => {
                        window.location.href = "/admin/ocean";
                        setBtnLoading(false)
                        setSnackBarInfo({
                            snackStatus: true,
                            snackColor: "bg-primary",
                            snackMsg: "Topic add successfully",
                        });
                        setIsLoaderInfo(false)
                    },
                    (err) => {
                        setBtnLoading(false)
                        setIsLoaderInfo(false)
                        console.log("err", err)
                        setSnackBarInfo({
                            snackStatus: true,
                            snackColor: "bg-danger",
                            snackMsg: err.data.message,
                        });
                    });

            } else {
                setErrMsg("Please select at least one Topic")
                setSnackBarInfo({
                    snackStatus: true,
                    snackColor: "bg-danger",
                    snackMsg: "Please select at least one Topic",
                });
            }

        } else {
            setStepForm(stepForm + 1)
        }

    }



    return (
        <>
            <Container fluid className='p-0 custom-welcome d-flex flex-column align-items-center justify-content-center'>
                {stepForm > 0 && (
                    <div className='progress-bar-cls w-100 d-flex align-items-center justify-content-center gap-5 mb-4'>
                        <CustomPrimaryButton
                            btnTextPro={<MdOutlineKeyboardArrowLeft size={40} />}
                            classNamePro="custom-progress-btn"
                            handleOnClick={handlePrevious}
                        />
                        <ProgressBar now={(stepForm) * (100 / totalSteps)} className="w-50" variant="warning" />
                    </div>
                )}
                <Row className='g-0 justify-content-center'>
                    <Col md={12} lg={12} className='align-items-center'>
                        {stepForm == 0 && (
                            <CustomWelcomeBox />
                        )}

                        {
                            topics && topics.map((item, ind) => (
                                stepForm === (ind + 1) && (
                                    <Row key={ind} >

                                        <Col md={12} lg={12}>
                                            <h3 className='skranji-bold text-white'>{item.question}</h3>
                                        </Col>

                                        <Col md={12} lg={6}>
                                            {item.answers.map((item2, ind2) => (
                                                ind2 < Math.ceil(item.answers.length / 2) &&
                                                <React.Fragment key={item2.id}>
                                                    <QuestionItem
                                                     welcomeicon={item2.image ? `${process.env.REACT_APP_BASE_URL}/${item2.image}` : item2.image} text={item2.answer} isClickedDisabled={false}
                                                        onClick={() => handleAnswerSelect(item2.id, item2.question_id)}
                                                        isSelected={selectedAnswers.some(ans => ans.question_id === item2.question_id && ans.answer.includes(item2.id))}
                                                    />
                                                </React.Fragment>
                                            ))}
                                        </Col>
                                        <Col md={12} lg={6}>
                                            {item.answers.map((item2, ind2) => (
                                                ind2 >= Math.ceil(item.answers.length / 2) &&
                                                <React.Fragment key={item2.id}>
                                                    <QuestionItem welcomeicon={item2.image ? `${process.env.REACT_APP_BASE_URL}/${item2.image}` : item2.image} text={item2.answer} isClickedDisabled={false}
                                                        onClick={() => handleAnswerSelect(item2.id, item2.question_id)}
                                                        isSelected={selectedAnswers.some(ans => ans.question_id === item2.question_id && ans.answer.includes(item2.id))}
                                                    />
                                                </React.Fragment>
                                            ))}
                                        </Col>

                                    </Row>
                                )

                            ))
                        }

                        {/* {stepForm === 2 && (
                            <Row>
                                <Col md={12} lg={12}>
                                    <h3 className='skranji-bold text-white'>Which topic do you want to learn more about?</h3>
                                </Col>

                                <Col md={12} lg={6}>
                                    <QuestionItem welcomeicon={linkedin} text="Linkedin"  isClickedDisabled={false}/>
                                    <QuestionItem welcomeicon={instagram} text="Instagram"  isClickedDisabled={false}/>
                                    <QuestionItem welcomeicon={micon} text="Monster"  isClickedDisabled={false}/>
                                    <QuestionItem welcomeicon={facebook} text="Facebook"  isClickedDisabled={false}/>
                                </Col>
                                <Col md={12} lg={6}>
                                    <QuestionItem welcomeicon={peoples} text="Through a friend"  isClickedDisabled={false}/>
                                    <QuestionItem welcomeicon={youtube} text="Youtube" isClickedDisabled={false} />
                                    <QuestionItem welcomeicon={googlesmall} text="Google" isClickedDisabled={false} />
                                    <QuestionItem welcomeicon={dots} text="Other" isClickedDisabled={false} />
                                </Col>
                            </Row>
                        )} */}

                        {/* {stepForm === 3 && (
                            <Row>
                                <Col md={12} lg={12}>
                                    <h3 className='skranji-bold text-white'>Which topic do you want to learn more about?</h3>
                                </Col>

                                <Col md={12} lg={12}>
                                    <QuestionItem welcomeicon={pad} text="By helping me prepare for formal examination" />
                                    <QuestionItem welcomeicon={bag} text="I want to start a Business" />
                                    <QuestionItem welcomeicon={coin} text="I want to invest in Crypto" />
                                    <QuestionItem welcomeicon={building} text="I want to prepare for a changing workplace" />
                                    <QuestionItem welcomeicon={analytics} text="I’m interested in stocks and shares" />
                                    <QuestionItem welcomeicon={dots} text="Other reason" />
                                </Col>
                            </Row>
                        )} */}

                    </Col>
                    {errMsg &&
                        <Col md={12} className='align-items-center'>
                            <p className='error-message'>{errMsg}</p>
                        </Col>
                    }
                </Row>


            </Container>

            <div className='welcome-bottom-box container-fluid'>
                <CustomPrimaryButton
                    btnTextPro="Continue"
                    btnLoading={btnLoading}
                    handleOnClick={() => handleTopicsSend()}
                    classNamePro="btn-custom-welcome"
                    customWelcomeClass="custom-welcome-class"
                />
            </div>
        </>
    )
}

export default Welcome