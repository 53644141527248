import React, { useEffect, useState } from 'react';
import TableComponentWithPagination from '../../components/globals/TableComponentWithPagination';
import { ENDPOINTS } from '../../utils/EndPoint';
import { useNavigate, useParams } from 'react-router-dom';
import { apiPost } from '../../utils/Api';
import { Tab, Tabs } from 'react-bootstrap';

function UserDetails() {
  const param = useParams();
  const userId = param.userId;
  const navigate = useNavigate();

  const [user, setUser] = useState({});
  const [quest, setQuest] = useState({});
  const [challenge, setChallenge] = useState({});
  const [badge, setBadge] = useState({});
  const [stage, setStage] = useState({});
  const [transaction, setTransaction] = useState({});
  const [topics, setTopics] = useState({});
  const [leaderboard, setLeaderboard] = useState({});
  const [ocean, setOcean] = useState({});


  const groupedTopics = Array.isArray(topics)
    ? topics.reduce((acc, topic) => {
      const questionId = topic.question.id;
      if (!acc[questionId]) {
        acc[questionId] = {
          question: topic.question,
          answers: []
        };
      }
      acc[questionId].answers.push(topic.answer);
      return acc;
    }, {})
    : {};

  const userDetail = () => {
    apiPost(
      `${ENDPOINTS.UserDetail}/${userId}`,
      { id: userId },
      (res) => {
        setUser(res?.data?.user);
        setQuest(res?.data?.user_quests);
        setChallenge(res?.data?.challenge_quest);
        setBadge(res?.data?.badges);
        setStage(res?.data?.stages);
        setTransaction(res?.data?.purchase_coins);
        setTopics(res?.data?.user_topics);
        setLeaderboard(res?.data?.leaderboard);
        setOcean(res?.data?.oceans);
      },
      (err) => {
        console.log("Error fetching user details", err);
      }
    );
  };

  useEffect(() => {
    userDetail();
  }, [userId]);

  const getRankSuffix = (position) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const moduloTen = position % 10;
    const moduloHundred = position % 100;

    return (
      suffixes[(moduloTen <= 3 && moduloTen > 0 && moduloHundred !== 11) ? moduloTen : 0] || 'th'
    );
  };

  const formatRank = (position) => {
    return `${position}${getRankSuffix(position)}`;
  };

  const questColumns = [
    { name: 'ID', selector: (row) => row?.id, sortable: false },
    { name: 'Ocean Name', selector: (row) => row?.ocean?.name, sortable: false },
    { name: 'Time', selector: (row) => row?.time, sortable: false },
    { name: 'Accuracy', selector: (row) => row?.accuracy, sortable: false },
    { name: 'Status', selector: (row) => row?.status, sortable: false },
  ];

  const challengeColumns = [
    { name: 'ID', selector: (row) => row?.id, sortable: false },
    { name: 'Ocean Name', selector: (row) => row?.ocean?.name, sortable: false },
    { name: 'Time', selector: (row) => row?.time, sortable: false },
    { name: 'Accuracy', selector: (row) => row?.accuracy, sortable: false },
    { name: 'Status', selector: (row) => row?.status, sortable: false },
  ];

  const badgeColumns = [
    { name: 'ID', selector: (row) => row?.id, sortable: false },
    { name: 'Goal Name', selector: (row) => row?.goal_name, sortable: false },
    { name: 'Description', selector: (row) => row?.description.replace(/<[^>]*>/g, ''), sortable: false },
    { name: 'Coins Reward', selector: (row) => row?.coins_reward, sortable: false },
  ];

  const purchaseCoinsColumns = [
    { name: 'ID', selector: (row) => row?.id, sortable: false },
    { name: 'Coins', selector: (row) => row?.amount, sortable: false },
    { name: 'Transaction Type', selector: (row) => row?.transaction_type_label, sortable: false },
    { name: 'Coin Type', selector: (row) => row?.coin_type, sortable: false },
    { name: 'Transaction Date', selector: (row) => new Date(row?.date).toLocaleString(), sortable: false },
  ];

  const stageColumns = [
    { name: 'ID', selector: (row) => row?.id, sortable: false },
    { name: 'Ocean Name', selector: (row) => row?.ocean?.name, sortable: false },
    { name: 'Stage Name', selector: (row) => row?.user_stage?.name, sortable: false },
    { name: 'Time', selector: (row) => row?.time, sortable: false },
    { name: 'Accuracy', selector: (row) => row?.accuracy, sortable: false },
    { name: 'Stars', selector: (row) => row?.stars, sortable: false },
    { name: 'Coins Spent', selector: (row) => row?.spend_coins, sortable: false },
  ];

  const leaderboardColumns = [
    { name: 'Rank', selector: (row) => formatRank(row.position), sortable: true },
    { name: 'User Name', selector: (row) => row?.user?.name, sortable: true },
    { name: 'Ocean', selector: (row) => row?.ocean?.name, sortable: true },
    { name: 'Accuracy', selector: (row) => row.accuracy, sortable: true },
    { name: 'Time (s)', selector: (row) => row.time, sortable: true },
    { name: 'Score', selector: (row) => row.score, sortable: true },
  ];

  const oceanColumns = [
    { name: 'ID', selector: (row) => row?.id, sortable: false },
    { name: 'Name', selector: (row) => row?.ocean?.name, sortable: false },
    {
      name: 'Image',
      selector: (row) => (
        <img
          src={`${process.env.REACT_APP_BASE_URL}/${row?.ocean?.image}`}
          alt={`${row?.ocean?.name}`}
          style={{ width: '50px', height: '50px', objectFit: 'none', borderRadius: '5px' }}
        />
      ),
      sortable: false,
    },
  ];

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-lg-4 mt-2'>
          <div className="card mb-4">
            <div className="card-header">
              <h5 className="mb-0">User Details</h5>
            </div>
            <div className="card-body">
              <p><strong>ID:</strong> {user.id}</p>
              <p><strong>Name:</strong> {user.name}</p>
              <p><strong>Email:</strong> {user.email}</p>
              <p><strong>Country:</strong> {user.country}</p>
              <p><strong>Signup Platform:</strong> {user.signup_platform}</p>
              <p><strong>Total Coins:</strong> {user.total_coins}</p>
              <p><strong>Daily Streak Count:</strong> {user.daily_streak_count}</p>
              <p><strong>Joined Date:</strong> {new Date(user.created_at).toLocaleString()}</p>
              <div>
                {Object.keys(groupedTopics).map((questionId) => {
                  const { question, answers } = groupedTopics[questionId];
                  return (
                    <div key={questionId}>
                      <h6><strong>{question.question}</strong></h6>
                      <ul>
                        {answers.map((answer, index) => (
                          <li key={index}>
                            {answer.answer}
                            {answer.is_correct ? <strong> (Correct)</strong> : null}
                          </li>
                        ))}
                      </ul>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-8 mt-2'>
          <Tabs defaultActiveKey="quests" id="user-details-tabs" className="mb-3">
            <Tab eventKey="quests" title="Quests">
              <TableComponentWithPagination
                title={"User Quests"}
                data={quest?.data || []}
                columns={questColumns}
                paginationData={quest}
              />
            </Tab>
            <Tab eventKey="challenges" title="Challenges">
              <TableComponentWithPagination
                title={"User Challenges"}
                data={challenge?.data || []}
                columns={challengeColumns}
                paginationData={challenge}
              />
            </Tab>
            <Tab eventKey="badges" title="Badges">
              <TableComponentWithPagination
                title={"User Badges"}
                data={badge?.data || []}
                columns={badgeColumns}
                paginationData={badge}
              />
            </Tab>
            <Tab eventKey="oceans" title="Oceans">
              <TableComponentWithPagination
                title={"Oceans"}
                data={ocean?.data || []}
                columns={oceanColumns}
                paginationData={ocean}
              />
            </Tab>
            <Tab eventKey="stages" title="Stages">
              <TableComponentWithPagination
                title={"User Stages"}
                data={stage?.data || []}
                columns={stageColumns}
                paginationData={stage}
              />
            </Tab>
            <Tab eventKey="purchase_coins" title="Transactions">
              <TableComponentWithPagination
                title={"Transactions"}
                data={transaction?.data || []}
                columns={purchaseCoinsColumns}
                paginationData={transaction}
              />
            </Tab>
            <Tab eventKey="leaderboard" title="Leaderboard">
              <TableComponentWithPagination
                title={"Leaderboard"}
                data={leaderboard?.data || []}
                columns={leaderboardColumns}
                paginationData={leaderboard}
              />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default UserDetails;
