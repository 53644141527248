import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import QuestionItem from '../components/globals/QuestionItem'
import CustomPrimaryButton from '../components/globals/CustomPrimaryButton'
import ProgressBar from 'react-bootstrap/ProgressBar'
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import CustomStageBox from '../components/globals/CustomStageBox'
import { FaVolumeHigh } from 'react-icons/fa6'
import { FaBookReader } from 'react-icons/fa'
import jumpingshark from '../assets/images/jumping-shark.gif'
import { GiSkills } from 'react-icons/gi'
import { oceanAtom } from "../store/oceanAtom"
import { apiPost } from '../utils/Api';
import { ENDPOINTS } from '../utils/EndPoint';
import { useRecoilState } from 'recoil'
import { isLoaderState, snakeBarState } from "../store/RecoilStore"
import { useParams } from 'react-router-dom';
import bulb from '../assets/images/coinsaccuracy.png'
import AccuracyItem from '../components/globals/AccuracyItem'



function Stages() {

    let { stageId } = useParams();

    const [stepForm, setStepForm] = useState(0);
    const totalSteps = 2;

    const [ocean, setOcean] = useRecoilState(oceanAtom);
    const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);
    const [snackBarInfo, setSnackBarInfo] = useRecoilState(snakeBarState);

    const [accuracy, setAccuracy] = useState(0);
    const [coins, setCoins] = useState(0);

    const [lessons, setLessons] = useState([])
    const [lessonIndex, setLessonIndex] = useState(0)
    const [questionIndex, setQuestionIndex] = useState(0)
    const [selectedAnswer, setSelectedAnswer] = useState(null)



    useEffect(() => {
        getLessons()
    }, [])

    const getLessons = async () => {
        setIsLoaderInfo(true)
        let payload = {
            stage_id: stageId,
            ocean_id: ocean.id
        }
        apiPost(
            ENDPOINTS.GetLessons,
            payload,
            (res) => {
                let filterData = res.data.filter((item) => {
                    if (!item.is_lesson_complete) {
                        return item
                    }
                });
                setLessons(filterData)
                setIsLoaderInfo(false)
            },
            (err) => {
                console.log("err", err)
                setIsLoaderInfo(false)
            });
    }

    const lessonsComplete = async () => {

        let payload = {
            ocean_id: selectedAnswer.ocean_id,
            lesson_id: selectedAnswer.lesson_id,
            stage_id: selectedAnswer.stage_id
        }
        apiPost(
            ENDPOINTS.CompleteLesson,
            payload,
            (res) => {
                console.log(res, "resresres");

            },
            (err) => {
                console.log("err", err)
            });
    }

    const completeQuiz = async () => {
        let payload = {
            ocean_id: selectedAnswer.ocean_id,
            time: 10,
            stage_id: selectedAnswer.stage_id
        }
        apiPost(
            ENDPOINTS.CompleteQuiz,
            payload,
            (res) => {
                if (res?.data) {
                    let accuracyValue = parseFloat(res.data.accuracy);
                    setAccuracy(accuracyValue.toFixed(2));

                    let coinsValue = parseFloat(res.data.spend_coins);
                    setCoins(coinsValue.toFixed(2));

                } else {
                    setAccuracy(0);
                    setCoins(0);
                }
                setStepForm(3);
            },
            (err) => {
                console.log("Error:", err);
                setAccuracy(0);
                setCoins(0);
            }
        );
    }

    const handlePrevious = () => {
        if (stepForm > 0) {
            setStepForm(stepForm - 1);
        }
    };

    const navigate = useNavigate();


    if (stepForm === 3) {
        // navigate("/admin/ocean");
    }

    const createMarkup = (html) => {
        return { __html: html };
    };


    const handleAnswer = (item) => {

        setSelectedAnswer(item)


    }

    const handleQuestion = () => {
        if (selectedAnswer) {
            const body = {
                question_id: selectedAnswer?.question_id,
                answer_id: selectedAnswer?.answer_id,
                ocean_id: selectedAnswer?.ocean_id,
                lesson_id: selectedAnswer?.lesson_id,
                stage_id: selectedAnswer?.stage_id,
            }
            setIsLoaderInfo(true)
            apiPost(
                ENDPOINTS.AddQuestionAnswers,
                body,
                (res) => {

                    if (lessons[lessonIndex]?.questions[lessons[lessonIndex]?.questions?.length - 1].id == selectedAnswer.question_id) {
                        if (lessons[lessons.length - 1].id == selectedAnswer.lesson_id) {
                            lessonsComplete()
                            completeQuiz()
                            setStepForm(3);
                            // navigate("/")
                        } else {
                            setStepForm(1)
                            setQuestionIndex(0)
                            let filterLessons = lessons.filter((item, index) => (index != 0 && item))
                            setLessons(filterLessons)
                            lessonsComplete()
                        }
                    } else {
                        setQuestionIndex(questionIndex + 1)
                    }
                    setIsLoaderInfo(false)
                    setSelectedAnswer(null)
                },
                (err) => {
                    console.log("err", err)
                    setIsLoaderInfo(false)
                });
        } else {
            setSnackBarInfo({
                snackStatus: true,
                snackColor: "bg-danger",
                snackMsg: "Please select anwser",
            });
        }

    }


    return (
        <>
            <Container fluid className='p-0 custom-welcome d-flex flex-column align-items-center justify-content-center'>
                {stepForm == 0 && (
                    <div className='mb-4 stage-btn-first'>
                        <CustomPrimaryButton
                            btnTextPro={<MdOutlineKeyboardArrowLeft size={40} />}
                            classNamePro="custom-progress-btn"
                            handleOnClick={() => {
                                navigate('/admin/ocean');
                            }}
                        />
                    </div>
                )}

                <Row className='g-0 justify-content-center'>

                    <Col md={12} lg={12} className='align-items-center'>

                        {stepForm > 0 && stepForm < 3 && (
                            <div className={`progress-bar-cls w-100 d-flex align-items-center gap-5 mb-4 mt-3 ${stepForm > 0 ? 'justify-content-start' : 'justify-content-center'}`}>
                                <CustomPrimaryButton
                                    btnTextPro={<MdOutlineKeyboardArrowLeft size={40} />}
                                    classNamePro="custom-progress-btn"
                                    // handleOnClick={handlePrevious}
                                    handleOnClick={() => { navigate('/admin/ocean') }}
                                />
                                {stepForm > 1 && (
                                    <ProgressBar now={(questionIndex) * (100 / lessons[lessonIndex]?.total_questions)} className="w-100" variant="warning" />
                                )}
                            </div>
                        )}

                        {stepForm == 0 && (
                            <CustomStageBox />
                        )}

                        {lessons && lessons.length > 0 &&
                            <>
                                {stepForm === 1 && (
                                    <div className='container'>
                                        <div className='row text-white'>
                                            <h5 className='skranji-regular mb-3'>Lesson</h5>
                                            <h1 className='mb-3 mt-3'>{lessons[lessonIndex]?.name}</h1>
                                            <div className='d-flex align-items-center mt-3 mb-3'>
                                                <div className='d-flex align-items-center me-4'>
                                                    <GiSkills />
                                                    <p className='mb-0 ms-1'><strong>{lessons[lessonIndex]?.ocean?.name}</strong></p>
                                                </div>

                                                <div className='d-flex align-items-center me-4'>
                                                    <FaBookReader />
                                                    <p className='mb-0 ms-1'><strong>{lessons[lessonIndex]?.total_questions} Question</strong></p>
                                                </div>

                                                {/* <div className='d-flex align-items-center me-4'>
                                                    <FaClock />
                                                    <p className='mb-0 ms-1'>5 minutes</p>
                                                </div> */}
                                            </div>
                                            <div className='pt-4 pb-4 d-flex justify-content-between align-items-center custom-stage-border'>
                                                <CustomPrimaryButton
                                                    btnTextPro="Start"
                                                    handleOnClick={() => setStepForm(stepForm + 1)}
                                                />

                                                <CustomPrimaryButton
                                                    btnTextPro={<FaVolumeHigh size={30} />}
                                                />
                                            </div>

                                            <div className='description-content'>
                                                <p className='font-22 mt-4' dangerouslySetInnerHTML={createMarkup(lessons[lessonIndex]?.description)} />
                                            </div>

                                            <img src={jumpingshark} className='jumpingshark' />
                                        </div>
                                    </div>

                                )}

                                {stepForm === 2 &&
                                    lessons[lessonIndex]?.questions?.length > 0 &&
                                    lessons[lessonIndex]?.questions?.map((item, ind) => (
                                        questionIndex == ind && (
                                            <Row key={ind}>
                                                <Col md={12} lg={12}>
                                                    <h3 className='skranji-bold text-white'>{item.question}</h3>
                                                </Col>

                                                <Col md={12} lg={12}>
                                                    {item?.answers.map((answer, ind2) => (
                                                        <QuestionItem key={ind2} text={answer?.answer} onClick={() => handleAnswer({
                                                            question_id: answer?.question_id,
                                                            answer_id: answer?.id,
                                                            ocean_id: answer?.ocean_id,
                                                            lesson_id: answer?.lesson_id,
                                                            stage_id: answer?.stage_id,
                                                        })} isSelected={selectedAnswer && selectedAnswer?.answer_id == answer?.id} />
                                                    ))}
                                                    {/* <QuestionItem text="To put money into something to make profit." isClickedDisabled={false} />
                                                <QuestionItem text="A spending plan based on income and expenses." isClickedDisabled={false} />
                                                <QuestionItem text="A privately owned company." /> */}
                                                </Col>
                                            </Row>
                                        )
                                    ))
                                }

                                {stepForm === 3 && (
                                    <div className='container text-center'>
                                        <p className='font-22 mt-4 text-white'>Develop active listening and clear articulation completed</p>
                                        <p className='font-22 mt-4 text-white'>Nice job reaching your goal</p>
                                        <AccuracyItem
                                            welcomeicon={bulb} customText="Accuracy" text={` ${accuracy ? accuracy : 0}%`}
                                        />
                                        <AccuracyItem
                                            welcomeicon={bulb} customText="Coins" text={` ${coins ? coins : 0}`}
                                        />
                                    </div>
                                )}

                            </>
                        }
                    </Col>
                </Row>

            </Container>

            <div className='welcome-bottom-box container-fluid'>
                {stepForm < 1 && (
                    <CustomPrimaryButton
                        btnTextPro="Continue"
                        handleOnClick={() => setStepForm(stepForm + 1)}
                        classNamePro="btn-custom-welcome"
                        customWelcomeClass="custom-welcome-class"
                    />
                )}

                {stepForm == 2 && (
                    <CustomPrimaryButton
                        btnTextPro="Continue"
                        handleOnClick={handleQuestion}
                        classNamePro="btn-custom-welcome"
                        customWelcomeClass="custom-welcome-class"
                    />
                )}

                {stepForm == 3 && (
                    <CustomPrimaryButton
                        btnTextPro="Continue"
                        handleOnClick={() => navigate('/')}
                        classNamePro="btn-custom-welcome"
                        customWelcomeClass="custom-welcome-class"
                    />
                )}

            </div>




        </>
    )
}

export default Stages