// src/components/SkillForm.js
import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import CustomTextField from '../../components/globals/CustomTextField';
import { apiPost } from '../../utils/Api';
import { ENDPOINTS } from '../../utils/EndPoint';
import { FaUserAlt } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';

const EditOcean = () => {
    const methods = useForm();
    const { handleSubmit, reset } = methods;
    const [submittedData, setSubmittedData] = useState(null);
    const navigate = useNavigate();
    const param = useParams();
    const ocean_id = param.ocean_id;

    const [ocean, setOcean] = useState();

    const oceanDetail = async () => {
        const oceanId = ocean_id;

        let payload = {
            ocean_id: oceanId,
        }

        apiPost(
            ENDPOINTS.AdminOceanDetailByIDPaginated,
            payload,
            (res) => {
                setOcean(res?.data);
            },
            (err) => {
                console.log("Error:", err);
            }
        )
    }

    useEffect(() => {
        oceanDetail();
    }, [ocean_id]);

    const onSubmit = (data) => {

        const formData = new FormData();

        formData.append('name', data?.name);
        formData.append('level', data?.level);
        formData.append('ocean_id', ocean?.id);

        if (data?.image && data?.image[0]) {
            formData.append('image', data?.image[0]);
        }

        apiPost(
            ENDPOINTS.AdminEditOcean,
            formData,
            (res) => {
                setSubmittedData(data);
                reset();
                navigate(`/super-admin/oceans`);
            },
            (err) => {
                console.log(err, "Error uploading the file");
            },
        );
    };

    return (
        <div className="container d-flex justify-content-center align-items-center min-vh-100">
            <div className="form-wrapper bg-light p-4 rounded shadow-sm">
                <h2 className="text-center mb-4">Edit Ocean</h2>

                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <CustomTextField
                            icon={<FaUserAlt />}
                            label="Name"
                            placeholder={ocean?.name}
                            name="name"
                            type="text"
                            classNamePro="ocean-field mb-3"
                            isRequired={true}
                            validation={{
                                required: "This field is required",
                            }}
                        />

                        <CustomTextField
                            label="Image"
                            placeholder="Upload Image"
                            name="image"
                            type="file"
                            classNamePro="ocean-field image-file-ocean mb-3"
                            isRequired={true}
                            validation={{
                                required: "This field is required",
                            }}
                        />

                        <div className="text-center">
                            <button type="submit" className="btn btn-primary w-100">
                                Submit
                            </button>
                        </div>
                    </form>
                </FormProvider>
            </div>
        </div>
    );
};

export default EditOcean;
