import React, { useState } from 'react';
import lockimage from '../assets/images/lockimage.png';
import CustomPrimaryButton from '../components/globals/CustomPrimaryButton';
import { useNavigate } from 'react-router-dom';
import CustomTextField from '../components/globals/CustomTextField';
import { FaEnvelope, FaLock } from 'react-icons/fa';
import CustomPasswordField from '../components/globals/CustomPasswordField';
import { useForm, FormProvider } from 'react-hook-form';
import PinInput from 'react-pin-input';
import { apiPostWithoutAuth } from '../utils/Api';
import { ENDPOINTS } from '../utils/EndPoint';
import { snakeBarState, isLoaderState } from "../store/RecoilStore"
import { useRecoilState } from 'recoil';



function ForgotPassword() {

    const [stepForm, setStepForm] = useState(1);
    const totalSteps = 3;

    const navigate = useNavigate();

    const [snackBarInfo, setSnackBarInfo] = useRecoilState(snakeBarState);
    const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);


    const [errMsg, setErrMsg] = useState("");
    const [btnLoading, setBtnLoading] = useState(false);

    const methods = useForm();
    const { setValue, watch } = methods;

    const otpData = watch("otp")
    const emailData = watch("email")



    const onSubmit = (data) => {

        setErrMsg("")

        if (stepForm == 1) {
            setIsLoaderInfo(true)
            const payload = {
                email: data.email,
                type: "forget_otp"
            }
            apiPostWithoutAuth(
                ENDPOINTS.SendOtp,
                payload,
                (res) => {
                    setStepForm(prev => prev + 1);
                    setErrMsg("")
                    setIsLoaderInfo(false)
                    setSnackBarInfo({
                        snackStatus: true,
                        snackColor: "bg-primary",
                        snackMsg: "OTP sent successfully",
                    });
                },
                (err) => {
                    console.log("err", err)
                    setErrMsg(err.data)
                    setIsLoaderInfo(false)
                    setSnackBarInfo({
                        snackStatus: true,
                        snackColor: "bg-danger",
                        snackMsg: err.data,
                    });
                }
            );
        }

        if (stepForm == 2) {
            setIsLoaderInfo(true)
            const payload = {
                email: data.email,
                otp: data.otp
            }
            apiPostWithoutAuth(
                ENDPOINTS.veriFyOTP,
                payload,
                (res) => {
                    setStepForm(prev => prev + 1);
                    setErrMsg("")
                    setIsLoaderInfo(false)
                    setSnackBarInfo({
                        snackStatus: true,
                        snackColor: "bg-primary",
                        snackMsg: "Verify successfully",
                    });
                },
                (err) => {
                    console.log("err", err)
                    setErrMsg(err.data)
                    setIsLoaderInfo(false)
                    setSnackBarInfo({
                        snackStatus: true,
                        snackColor: "bg-danger",
                        snackMsg: err.data,
                    });
                }
            );
        }

        if (stepForm == 3) {

            setBtnLoading(true)
            setIsLoaderInfo(true)

            const payload = {
                email: data.email,
                password: data.password,
                password_confirmation: data.password_confirmation,
                otp: data.otp
            }
            apiPostWithoutAuth(
                ENDPOINTS.ChangePassword,
                payload,
                (res) => {
                    window.location.href = "/login";
                    setBtnLoading(false)
                    setErrMsg("")
                    setIsLoaderInfo(false)
                    setSnackBarInfo({
                        snackStatus: true,
                        snackColor: "bg-primary",
                        snackMsg: "Password changed successfully",
                    });
                },
                (err) => {
                    console.log("err", err)
                    setErrMsg(err.data)
                    setIsLoaderInfo(false)
                    setSnackBarInfo({
                        snackStatus: true,
                        snackColor: "bg-danger",
                        snackMsg: err.data,
                    });
                    setBtnLoading(false)
                }
            );
        }



    };


    const handleResendOTP = () => {

        const payload = {
            email: emailData,
            type: "email_otp"
        }
        apiPostWithoutAuth(
            ENDPOINTS.SendOtp,
            payload,
            (res) => {
                setSnackBarInfo({
                    snackStatus: true,
                    snackColor: "bg-primary",
                    snackMsg: "OTP sent successfully",
                });
                setErrMsg("")
            },
            (err) => {
                console.log("err", err)
            }
        );

    }


    const handleVerifyClick = () => {
        // if (stepForm < totalSteps) {
        //     setStepForm(stepForm + 1);
        // } else {
        //     navigate("/login");
        // }
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div>
                    {stepForm === 1 && (
                        <div className='quest d-flex justify-content-center align-items-center'>
                            <div className='container text-white d-flex justify-content-center align-items-center'>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <h1 className='mb-3 text-center'>Forgot Password</h1>
                                        <h5 className='mb-5 text-center'>The faster you fill up, the faster you start
                                            learning </h5>
                                        <CustomTextField
                                            icon={<FaEnvelope />}
                                            label="Email"
                                            placeholder="chriswayne@gmail.com"
                                            name="email"
                                            type="email"
                                            classNamePro="mb-2"
                                            validation={{
                                                required: "This field is required",
                                            }}
                                        />
                                        {errMsg && <p className="error-message text-center">{errMsg}</p>}
                                        <CustomPrimaryButton btnTextPro="Send OTP" customWelcomeClass="mt-5 " />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {stepForm === 2 && (
                        <div className='quest d-flex justify-content-center align-items-center'>
                            <div className='container text-white d-flex justify-content-center align-items-center'>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <h1>Enter OTP code</h1>
                                        <img src={lockimage} className='lockimage mt-5 mb-5' alt="Lock" />
                                        <p className='text-warning text-center mt-2'>Please enter the 4-digit code sent to you</p>
                                        {/* <div className="d-flex justify-content-center mt-2">
                                            <input type="text" className="form-control otp-input" maxLength="1" />
                                            <input type="text" className="form-control otp-input" maxLength="1" />
                                            <input type="text" className="form-control otp-input" maxLength="1" />
                                            <input type="text" className="form-control otp-input" maxLength="1" />
                                        </div> */}
                                        <div className='pincode-field mt-2'>
                                            <PinInput
                                                length={4}
                                                initialValue=""
                                                secret
                                                secretDelay={100}
                                                onChange={(value, index) => { setValue('otp', value, { shouldDirty: true }); }}
                                                type="numeric"
                                                inputMode="number"
                                                style={{ padding: '10px' }}
                                                // inputStyle={{ borderColor: 'red' }}
                                                // inputFocusStyle={{ borderColor: 'blue' }}
                                                onComplete={(value, index) => { setValue('otp', value, { shouldDirty: true }) }}
                                                autoSelect={true}
                                                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                                            />
                                        </div>
                                        {errMsg && <p className="error-message text-center">{errMsg}</p>}
                                        <p className='text-center mt-2'>Didn’t receive OTP? <span onClick={handleResendOTP} className='text-warning'>Send again!</span></p>
                                        <CustomPrimaryButton btnTextPro="Verify" btnLoading={otpData == undefined || otpData.length < 4} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {stepForm === 3 && (
                        <div className='quest d-flex justify-content-center align-items-center'>
                            <div className='container text-white d-flex justify-content-center align-items-center'>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <h1 className='mb-3 text-center'>Reset Password</h1>
                                        <h5 className='mb-5 text-center'>Your new password must be different from
                                            the old password </h5>
                                        <CustomPasswordField
                                            label="Password"
                                            placeholder="************"
                                            name="password"
                                            type="password"
                                            icon={<FaLock />}
                                            classNamePro="mb-2"
                                            validation={{
                                                required: "This field is required",
                                            }}
                                        />

                                        <CustomPasswordField
                                            label="Confirm Password"
                                            placeholder="************"
                                            name="password_confirmation"
                                            type="password"
                                            icon={<FaLock />}
                                            classNamePro="mb-2"
                                            validation={{
                                                required: "This field is required",
                                            }}
                                        />

                                        {errMsg && <span className="error-message">{errMsg}</span>}

                                        <CustomPrimaryButton btnTextPro="Reset Password" customWelcomeClass="mt-5 " btnLoading={btnLoading} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </form>
        </FormProvider>
    );
}

export default ForgotPassword;
