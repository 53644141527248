import React, { useState } from 'react';
import TableComponent from '../../components/globals/TableComponent';
import { ENDPOINTS } from '../../utils/EndPoint';
import { useLocation, useNavigate } from 'react-router-dom';
import { apiPost } from '../../utils/Api';
import { FaEdit, FaTrash, FaEye } from 'react-icons/fa';


function AllQuests() {

  const navigate = useNavigate();
  const [quests, setQuest] = useState([]);
  const location = useLocation();
  
  
  const handleOnClick = async (quest_id) => {
    navigate(`/super-admin/admin-quests/${quest_id}`, {
      state: { quest_id },
    });
  };

  const handleAddClick = () => {
    navigate('/super-admin/add-quest');
  };

  const handleEditClick = async (quest_id) => {
    navigate(`/super-admin/edit-quest/${quest_id}`);
  };

  const deleteQuest = (questId) => {

    const payload = {
      id: questId,
    }

    apiPost(
      ENDPOINTS.AdminDeleteQuest,
      payload,
      (res) => {
        setQuest(res?.data);
        window.location.reload();
      },
      (err) => {
        console.log(err, 'Error deleting ocean');
      }
    )
  }

  const columns = [
    {
      name: 'ID',
      selector: (row) => row.id,
      sortable: false,
      width: '60px',
    },
    {
      name: 'Title',
      selector: (row) => row?.title,
      sortable: false,
    },
    {
      name: 'Level',
      selector: (row) => row?.level,
      sortable: false,
    },
    {
      name: 'Time',
      selector: (row) => row?.time,
      sortable: false,
    },
    {
      name: 'Reward Price',
      selector: (row) => row?.reward_price,
      sortable: false,
    },
    {
      name: 'Number Of Questions',
      selector: (row) => row?.questions_count,
      sortable: false,
    },
    {
      name: 'Ocean Name',
      selector: (row) => row?.ocean?.name,
      sortable: false,
    },
    {
      name: 'Description',
      selector: (row) => row?.description.replace(/<[^>]*>/g, ''),
      sortable: false,
    },
    {
      name: 'Actions',
      selector: (row) => (
        <div className='set-show-all'>
          <FaEye
            className="icon me-2"
            onClick={() => handleOnClick(row.id)}
            title="View"
          />
          <FaEdit
            className="icon me-2"
            onClick={() => handleEditClick(row.id)}
            title="Edit"
          />
          <FaTrash
            className="icon text-danger"
            onClick={() => {
              if (window.confirm("Are you sure you want to delete this quest?")) {
                deleteQuest(row.id);
              }
            }}
            title="Delete"
          />
        </div>
      ),
      sortable: false,
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3 mt-3 me-2">
        <h1 className='ms-2'>Quests</h1>
        <button
          className="btn btn-primary"
          onClick={handleAddClick}
        >
          Add Quest
        </button>
      </div>
      <TableComponent
        title={"Quests"}
        endPoint={ENDPOINTS.AdminQuestsPaginated}
        headers={columns}
      />
    </>

  );
}

export default AllQuests;
