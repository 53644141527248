// src/components/StageForm.js
import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import CustomTextField from '../../components/globals/CustomTextField';
import { apiGetAuth, apiPost } from '../../utils/Api';
import { ENDPOINTS } from '../../utils/EndPoint';
import { useNavigate, useParams } from 'react-router-dom';
import CustomSelectField from '../../components/globals/CustomSelectField';
import CustomTextAreaField from '../../components/globals/CustomTextAreaField';

const EditMiniQuiz = () => {
    const { mini_quiz_id } = useParams();
    const methods = useForm();
    const { handleSubmit, reset, setValue } = methods;
    const [submittedData, setSubmittedData] = useState(null);
    const navigate = useNavigate();
    const [oceans, setOceans] = useState([]);
    const [filteredStages, setFilteredStages] = useState([]);
    const [lessonData, setLessonData] = useState(null);

    const getLessonDetails = () => {
        apiGetAuth(
            `${ENDPOINTS.AdminMiniQuizDetailByID}?mini_quiz_id=${mini_quiz_id}`,
            (res) => {
                setLessonData(res);
                setValue('name', res.name);
                setValue('description', res.description);
                setValue('total_questions', res.total_questions);
                setValue('ocean_id', res.ocean_id);
                setValue('stage_id', res.stage_id);
                getStages(res.ocean_id);
            },
            (err) => {
                console.log(err, "Error fetching miniquiz details");
            }
        );
    };

    const onSubmit = (data) => {
        const formData = new FormData();

        formData.append('name', data.name);
        formData.append('description', data.description);
        formData.append('total_questions', data.total_questions);
        formData.append('ocean_id', data.ocean_id);
        formData.append('stage_id', data.stage_id);
        formData.append('mini_quiz_id', mini_quiz_id);

        if (data.mini_quiz_audio && data.mini_quiz_audio[0]) {
            formData.append('mini_quiz_audio', data.mini_quiz_audio[0]);
        }

        if (data.image[0]) {
            formData.append('image', data.image[0]);
        }

        apiPost(
            `${ENDPOINTS.AdminEditMiniQuiz}`,
            formData,
            (res) => {
                setSubmittedData(data);
                reset();
                navigate(`/super-admin/all-miniquiz`);
            },
            (err) => {
                console.log(err, "Error updating the data");
            },
        );
    };

    const getOceans = () => {
        apiGetAuth(
            ENDPOINTS.AdminOceansWithoutPaginated,
            (res) => {
                setOceans(res);
            },
            (err) => {
                console.log(err, "Error fetching oceans");
            }
        );
    };

    const getStages = (oceanId) => {
        apiGetAuth(
            `${ENDPOINTS.AdminStagesWithoutPaginated}?ocean_id=${oceanId}`,
            (res) => {
                setFilteredStages(res);
            },
            (err) => {
                console.log(err, "Error fetching stages");
            }
        );
    };

    const handleOceanChange = (selectedOption) => {
        if (selectedOption && selectedOption.value) {
            methods.setValue("ocean_id", selectedOption.value);
            methods.setValue("stage_id", "");
            getStages(selectedOption.value);
        }
    };

    useEffect(() => {
        getOceans();
        getLessonDetails();
    }, [mini_quiz_id]);

    return (
        <div className="container d-flex justify-content-center align-items-center min-vh-100">
            <div className="form-wrapper bg-light p-4 rounded shadow-sm">
                <h2 className="text-center mb-4">Edit Mini Quiz</h2>

                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>

                        {/* Name Field */}
                        <CustomTextField
                            label="Stage Name"
                            placeholder="Enter Stage Name"
                            name="name"
                            type="text"
                            classNamePro="stage-field mb-3"
                            isRequired={true}
                            validation={{
                                required: "Stage name is required",
                            }}
                        />

                        {/* Ocean Dropdown */}
                        <CustomSelectField
                            label="Select Ocean"
                            name="ocean_id"
                            options={oceans.map(ocean => ({ value: ocean.id, label: ocean.name }))}
                            classNamePro="ocean-field mb-3"
                            validation={{ required: 'This field is required' }}
                            onChange={(e) => {
                                const selectedValue = e.target.value;
                                const selectedOption = {
                                    value: selectedValue,
                                    label: oceans.find(ocean => ocean.id === selectedValue)?.name
                                };
                                handleOceanChange(selectedOption);
                            }}
                        />

                        {/* Stage ID Field */}
                        <CustomSelectField
                            label="Select Stage"
                            name="stage_id"
                            options={filteredStages.map(stage => ({ value: stage.id, label: stage.name }))}
                            classNamePro="ocean-field mb-3"
                            validation={{ required: 'This field is required' }}
                            onChange={(e) => {
                                const selectedValue = e.target.value;
                                methods.setValue("stage_id", selectedValue);
                            }}
                        />

                        {/* Number Of Questions Field */}
                        <CustomTextField
                            label="Number Of Questions"
                            placeholder="Enter Number Of Questions"
                            name="total_questions"
                            type="number"
                            classNamePro="stage-field mb-3"
                            isRequired={true}
                            validation={{
                                required: "Number Of Questions are required",
                                min: {
                                    value: 1,
                                    message: "Number Of Questions must be at least 1",
                                },
                            }}
                        />

                        {/* Image Upload Field */}
                        <CustomTextField
                            label="Upload Image"
                            name="image"
                            type="file"
                            classNamePro="stage-field mb-3"
                            isRequired={false}
                        />

                        <CustomTextField
                            label="Audio"
                            name='mini_quiz_audio'
                            type="file"
                            accept="audio/*"
                            classNamePro="ocean-field mb-3"
                            isRequired={false}
                        />

                        {/* Description Field */}
                        <CustomTextAreaField
                            label="Description"
                            placeholder="Enter Description"
                            name="description"
                            classNamePro="stage-field mb-3"
                            isRequired={false}
                            value={methods.watch("description")}
                            onChange={(data) => methods.setValue("description", data)}
                        />

                        <div className="text-center">
                            <button type="submit" className="btn btn-primary w-100">
                                Update
                            </button>
                        </div>
                    </form>
                </FormProvider>
            </div>
        </div>
    );
};

export default EditMiniQuiz;
