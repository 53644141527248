import React, { useState, useEffect } from 'react';
import CustomBusinessMoneyButton from '../components/globals/CustomBusinessMoneyButton';
import OceanStage from '../components/globals/OceanStage';
import BuyCoinComponent from '../components/globals/BuyCoinComponent';
import { useNavigate } from 'react-router-dom';
import CustomModal from '../components/globals/CustomModal';
import QuestionItem from '../components/globals/QuestionItem';
import { oceanAtom } from "../store/oceanAtom"
import { apiGetAuth, apiPost } from '../utils/Api';
import { ENDPOINTS } from '../utils/EndPoint';
import { useRecoilState } from 'recoil'
import { isLoaderState } from "../store/RecoilStore"
import { userAtom } from '../store/authAtom';


const Ocean = () => {

  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

  const [lastCompleteIndex, setLastCompleteIndex] = useState(0);

  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const [oceans, setOceans] = useState([]);
  const [stages, setStages] = useState([]);

  const [ocean, setOcean] = useRecoilState(oceanAtom);
  const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);

  const [user, setUser] = useRecoilState(userAtom);


  useEffect(() => {
    getOceans();
  }, [])

  // useEffect(() => {
  //   if (ocean) {
  //     getStages();
  //   }
  // }, [ocean])

  const getOceans = async () => {
    setIsLoaderInfo(true)
    apiGetAuth(
      ENDPOINTS.GetOceans,
      (res) => {
        const oceanWithUserOption = res.find(ocean => ocean.user_selected_option != null);
        if (oceanWithUserOption) {
          setOcean(oceanWithUserOption);
          getStages(oceanWithUserOption?.id);
        } else {
          const defaultOcean = res[0];
          if (defaultOcean) {
            setOcean(defaultOcean);
            getStages(defaultOcean?.id);
          }
        }
        setOceans(res)
        setIsLoaderInfo(false)
      },
      (err) => {
        console.log("err", err)
        setIsLoaderInfo(false)
      }
    );
  }

  const getStages = async (oceanId) => {
    setIsLoaderInfo(true)
    apiGetAuth(
      `${ENDPOINTS.GetStages}?ocean_id=${oceanId}`,
      (res) => {
        const count = res.filter(stage => stage.is_stage_complete !== null).length;
        setStages(res)
        setLastCompleteIndex(count)
        setIsLoaderInfo(false)
      },
      (err) => {
        console.log("err", err)
        setIsLoaderInfo(false)
      }
    );
  }

  const getLessons = async (stageId) => {
    setIsLoaderInfo(true)
    let payload = {
      stage_id: stageId,
      ocean_id: ocean?.id
    }
    apiPost(
      ENDPOINTS.GetLessons,
      payload,
      (res) => {

        setIsLoaderInfo(false)
      },
      (err) => {
        console.log("err", err)
        setIsLoaderInfo(false)
      });
  }

  const startDeductCoins = async (stageId) => {
    let payload = {
      stage_id: stageId,
      ocean_id: ocean?.id
    }
    apiPost(
      ENDPOINTS.StartQuizDeductCoins,
      payload,
      (res) => {
      },
      (err) => {
        console.log("Error:", err);
      }
    );
  }

  const getCoins = async () => {
    apiGetAuth(
      ENDPOINTS.GetCoins,
      (res) => {
        setUser((prevUser) => ({
          ...prevUser,
          total_coins: res.total_coins,
        }));
      },
      (
        err) => {
        console.log("Error:", err);
      }
    );
  }

  const handleSelectOcean = (item) => {
    setOcean(item)
    apiPost(
      ENDPOINTS.UserSelectedOption,
      { ocean_id: item?.id },
      (res) => {
        getStages(item?.id)
        setShowModal(false);
      },
      (err) => {
        console.log("Error updating user selected option", err);
      }
    );
  }


  return (
    <div className='container-fluid p-4 bg-ocean'>
      <div className='d-flex justify-content-between align-items-center custom-ocean-mb-class'>
        <CustomBusinessMoneyButton
          btnTextPro={ocean ? ocean?.name : "Select Ocean"}
          classNamePro="btn-custom-leaderboard"
          customWelcomeClass="custom-leaderboard-class"
          handleOnClick={() => setShowModal(true)}
        />
        <BuyCoinComponent handleOnClick={() => navigate('/admin/coin-purchase')} />
      </div>

      <div className='dashboard-container'>
        <div className='pt-5 pb-5'>
          <div className='ocean-row'>

            {stages.map((stage, index) => (
              <OceanStage
                key={index}
                item={stage}
                lastCompleteIndex={lastCompleteIndex}
                arrayLength={stage?.length}
                indexNumber={index + 1}
                descriptionPro={`Stage ${index + 1}`}
                handleOnClick={() => {
                  getLessons(stage?.id);
                  startDeductCoins(stage?.id);
                  getCoins();
                  navigate(`/stages/${stage?.id}`);
                }}
              />
            ))}
          </div>
        </div>
      </div>


      <CustomModal
        size={'md'}
        hideModal={() => setShowModal(false)}
        showModal={showModal}
        title="Select Ocean" onClose={() => setShowModal(false)}>
        <div className='ocean-modal text-center'>
          <h2 className='text-white'>Select Ocean</h2>
          {oceans && oceans.length > 0 ?
            oceans.map((item, ind) => (
              <React.Fragment key={ind}>
                <QuestionItem welcomeicon={`${process.env.REACT_APP_BASE_URL}/${item.image}`} text={item.name} progressBar={{ now: item.percentage }} customTime="4 Hours"
                  onClick={() => handleSelectOcean(item)}
                  isSelected={ocean?.id == item?.id}
                />
              </React.Fragment>
            ))
            :
            <div><p>No Ocean</p></div>}
          {/* <QuestionItem welcomeicon={ai} text="Business & money skillz" progressBar={{ now: 30 }} customTime="4 Hours" />
          <QuestionItem welcomeicon={analytics} text="Business & money skillz" progressBar={{ now: 90 }} customTime="4 Hours" />
          <QuestionItem welcomeicon={ai} text="Business & money skillz" progressBar={{ now: 90 }} customTime="4 Hours" />
          <QuestionItem welcomeicon={coin} text="Business & money skillz" progressBar={{ now: 90 }} customTime="4 Hours" /> */}
        </div>
      </CustomModal>
    </div>
  );
};

export default Ocean;
