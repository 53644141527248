import React, { useEffect, useState } from 'react';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import CustomTextField from '../../components/globals/CustomTextField';
import { apiGetAuth, apiPost } from '../../utils/Api';
import { ENDPOINTS } from '../../utils/EndPoint';
import { useNavigate } from 'react-router-dom';
import CustomSelectField from '../../components/globals/CustomSelectField';
import { FaPlusCircle, FaTrash } from 'react-icons/fa';

const AddChallengeQuestionAnswers = () => {
    const methods = useForm({
        defaultValues: {
            questions: [{ question: '', answers: [{ text: '', is_correct: false }, { text: '', is_correct: false }, { text: '', is_correct: false }, { text: '', is_correct: false }] }],
            ocean_id: '',
            challenge_id: '',
        },
    });

    const [oceans, setOceans] = useState([]);
    const [filteredStages, setFilteredStages] = useState([]);
    const [filteredLessons, setFilteredLessons] = useState([]);
    const [questionsCount, setQuestionsCount] = useState(0);
    const [quest, setQuestDetail] = useState(0);
    const [questions, setQuestions] = useState([]);
    const [selectedOceanId, setSelectedOceanId] = useState(null);

    const { handleSubmit, reset, watch, setValue, control } = methods;
    const { fields: questionFields, append: appendQuestion, remove: removeQuestion } = useFieldArray({
        control,
        name: 'questions',
    });

    const navigate = useNavigate();

    const { fields: answerFields, append: appendAnswer, remove: removeAnswer } = useFieldArray({
        control,
        name: `questions[${questionFields.length - 1}].answers`,
    });


    const onSubmit = (data) => {
        const formData = new FormData();
        formData.append('ocean_id', data.ocean_id);
        formData.append('challenge_id', data.challenge_id);

        if (questions > 0) {
            data.questions.forEach((question, index) => {
                formData.append(`questions[${index}][question]`, question.question);
                if (question.question_audio) {
                    formData.append(`questions[${index}][question_audio]`, question.question_audio[0]);
                }

                question.answers.forEach((answer, answerIndex) => {
                    formData.append(`questions[${index}][answers][${answerIndex}][answer]`, answer.text);
                    formData.append(`questions[${index}][answers][${answerIndex}][is_correct]`, answer.is_correct ? 1 : 0);

                    if (answer.answer_audio) {
                        formData.append(`questions[${index}][answers][${answerIndex}][answer_audio]`, answer.answer_audio[0]);
                    }
                });
            });

            apiPost(
                ENDPOINTS.AdminAddChallengeQuestionsAnswers,
                formData,
                (res) => {
                    reset();
                    navigate('/super-admin/all-challenges');
                },
                (err) => {
                    console.error(err, "Error uploading the data");
                },
            );
        } else {
            if (questionsCount !== data.questions.length) {
                alert(`The number of questions must match the predefined count: ${questionsCount}`);
                return;
            } else {
                data.questions.forEach((question, index) => {
                    formData.append(`questions[${index}][question]`, question.question);
                    if (question.question_audio) {
                        formData.append(`questions[${index}][question_audio]`, question.question_audio[0]);
                    }

                    question.answers.forEach((answer, answerIndex) => {
                        formData.append(`questions[${index}][answers][${answerIndex}][answer]`, answer.text);
                        formData.append(`questions[${index}][answers][${answerIndex}][is_correct]`, answer.is_correct ? 1 : 0);

                        if (answer.answer_audio) {
                            formData.append(`questions[${index}][answers][${answerIndex}][answer_audio]`, answer.answer_audio[0]);
                        }
                    });
                });

                apiPost(
                    ENDPOINTS.AdminAddChallengeQuestionsAnswers,
                    formData,
                    (res) => {
                        reset();
                        navigate('/super-admin/all-challenges');
                    },
                    (err) => {
                        console.error(err, "Error uploading the data");
                    },
                );
            }
        }

    };

    const getOceans = () => {
        apiGetAuth(
            ENDPOINTS.AdminOceansWithoutPaginated,
            (res) => {
                setOceans(res);
            },
            (err) => {
                console.error(err, "Error fetching oceans");
            }
        );
    };

    const questDetail = async (challenge_id) => {
        let payload = {
            challenge_id: challenge_id,
        };
        apiPost(
            ENDPOINTS.AdminChallengeDetailByIDPaginated,
            payload,
            (res) => {
                const questData = res?.data;
                setQuestionsCount(questData?.questions_count || 0);
            },
            (err) => {
                console.log("Error:", err);
            }
        );
    };

    const fetchQuestions = (oceanId, challengeId) => {
        const payload = {
            challenge_id: challengeId,
            ocean_id: oceanId,
        };

        apiPost(
            ENDPOINTS.AdminChallengeQuestionsAnswers,
            payload,
            (res) => {
                setQuestions(res?.data?.data.length || []);
            },
            (err) => {
                console.log(err, 'Error fetching questions');
            }
        );
    };

    const getQuests = (oceanId) => {
        apiGetAuth(
            `${ENDPOINTS.AdminChallengesWithoutPaginated}?ocean_id=${oceanId}`,
            (res) => {
                setFilteredStages(res);
                setValue("challenge_id", "");
                setFilteredLessons([]);
            },
            (err) => {
                console.error(err, "Error fetching stages");
            }
        );
    };

    const handleOceanChange = (selectedOption) => {
        if (selectedOption?.value) {
            setValue("ocean_id", selectedOption.value);
            setSelectedOceanId(selectedOption.value);
            getQuests(selectedOption.value);
        }
    };

    const handleQuestChange = (selectedOption) => {
        if (selectedOption?.value) {
            setValue("challenge_id", selectedOption.value);
            questDetail(selectedOption.value);
            setQuestDetail(selectedOption.value);
            fetchQuestions(selectedOceanId, selectedOption.value);
        }
    };

    useEffect(() => {
        getOceans();
    }, []);

    return (
        <div className="container d-flex justify-content-center align-items-center min-vh-100">
            <div className="form-wrapper bg-light p-4 rounded shadow-sm">
                <h2 className="text-center mb-4">Add Question & Answers</h2>

                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>

                        {/* Ocean Dropdown */}
                        <CustomSelectField
                            label="Select Ocean"
                            name="ocean_id"
                            options={oceans.map(ocean => ({ value: ocean.id, label: ocean.name }))}
                            classNamePro="ocean-field mb-3"
                            validation={{ required: 'This field is required' }}
                            onChange={(e) => {
                                const selectedOption = {
                                    value: e.target.value,
                                    label: oceans.find(ocean => ocean.id === e.target.value)?.name
                                };
                                handleOceanChange(selectedOption);
                            }}
                        />

                        {/* Stage Dropdown */}
                        <CustomSelectField
                            label="Select Challenge"
                            name="challenge_id"
                            options={filteredStages.map(stage => ({ value: stage.id, label: stage.title }))}
                            classNamePro="ocean-field mb-3"
                            validation={{ required: 'This field is required' }}
                            onChange={(e) => {
                                const selectedOption = {
                                    value: e.target.value,
                                    label: filteredStages.find(stage => stage.id === e.target.value)?.title
                                };
                                handleQuestChange(selectedOption);
                            }}
                        />

                        {/* Questions Section */}
                        <div className="mb-3">
                            {watch("questions").map((question, index) => (
                                <div key={index}>
                                    <CustomTextField
                                        label={`Question ${index + 1}`}
                                        placeholder={`Enter Question`}
                                        name={`questions[${index}].question`}
                                        type="text"
                                        classNamePro="ocean-field mt-2"
                                        isRequired={true}
                                        validation={{ required: "Question is required" }}
                                    />

                                    <CustomTextField
                                        label={`Audio ${index + 1}`}
                                        placeholder={`Enter Question Audio File`}
                                        name={`questions[${index}].question_audio`}
                                        type="file"
                                        accept="audio/*"
                                        classNamePro="ocean-field mt-2"
                                        isRequired={false}
                                    />

                                    {question.answers.map((answer, answerIndex) => (
                                        <div key={answerIndex} className="d-flex align-items-center mt-2">
                                            <CustomTextField
                                                label={`Answer ${answerIndex + 1}`}
                                                placeholder={`Enter Answer ${answerIndex + 1}`}
                                                name={`questions[${index}].answers[${answerIndex}].text`}
                                                type="text"
                                                classNamePro="ocean-field me-2"
                                                isRequired={true}
                                                validation={{ required: "Answer is required" }}
                                            />

                                            <div className="d-flex align-items-center custom-text-field ocean-field mt-4">
                                                <input
                                                    type="radio"
                                                    name={`questions[${index}].is_correct`}
                                                    value={answerIndex}
                                                    checked={watch(`questions[${index}].answers[${answerIndex}].is_correct`)}
                                                    onChange={() => {
                                                        question.answers.forEach((_, i) => {
                                                            setValue(`questions[${index}].answers[${i}].is_correct`, i === answerIndex);
                                                        });
                                                    }}
                                                    className="me-2"
                                                />
                                                <label className="ms-2">Correct</label>

                                                <FaTrash
                                                    className="ms-2 text-danger"
                                                    onClick={() => removeAnswer(answerIndex)}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                            </div>
                                        </div>
                                    ))}

                                    <FaPlusCircle
                                        className="text-secondary"
                                        onClick={() => appendAnswer({ text: '', is_correct: false })}
                                        style={{ cursor: 'pointer', fontSize: '1.5em' }}
                                    />

                                    <FaTrash
                                        className="ms-2 text-danger"
                                        onClick={() => removeQuestion(index)}
                                        style={{ cursor: 'pointer', fontSize: '1.5em' }}
                                    />

                                </div>
                            ))}
                        </div>

                        <div
                            className="text-secondary mb-3"
                            style={{ cursor: 'pointer', fontSize: '1.5em' }}
                            onClick={() => appendQuestion({ question: '', answers: [{ text: '', is_correct: false }] })}
                        >
                            <FaPlusCircle /> Add Question
                        </div>

                        <div className="text-center">
                            <button type="submit" className="btn btn-primary w-100">
                                Submit
                            </button>
                        </div>
                    </form>
                </FormProvider>
            </div>
        </div>
    );
};

export default AddChallengeQuestionAnswers;