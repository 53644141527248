import './App.css';
import './custom.scss';
import Layout from './components/layout/Layout';
import ErrorPage from './pages/ErrorPage';
import { Route, BrowserRouter as Router, Routes, Navigate, redirect } from "react-router-dom";
import Ocean from './pages/Ocean';
import Loader from './components/globals/Loader';
import { useRecoilState } from 'recoil';
import { isLoaderState, snakeBarState } from './store/RecoilStore';
import Login from './pages/Login';
import Register from './pages/Register';
import LeaderBoard from './pages/LeaderBoard';
import CoinPurchase from './pages/CoinPurchase';
import Quest from './pages/Quest';
import Wallet from './pages/Wallet';
import Profile from './pages/Profile';
import RegisterForm from './pages/RegisterForm';
import Welcome from './pages/Welcome';
import EditProfile from './pages/EditProfile';
import Stages from './pages/Stages';
import ForgotPassword from './pages/ForgotPassword';
import VerifyOTP from './pages/VerifyOTP';
import Guest from './pages/Guest';
import PrivateRoutes from './components/auth/PrivateRoutes';
import AuthRoutes from './components/auth/AuthRoutes';
import SnackBar from './components/globals/SnackBar';
import SuperAdminRoutes from './components/auth/SuperAdminRoutes';
import SuperAdminDashboard from './pages/superadmin/SuperAdminDashboard';
import Users from './pages/superadmin/Users';
import Oceans from './pages/superadmin/Oceans';
import AdminStages from './pages/superadmin/AdminStages';
import AdminLessons from './pages/superadmin/AdminLessons';
import AllStages from './pages/superadmin/AllStages';
import AllLessons from './pages/superadmin/AllLessons';
import AllQuests from './pages/superadmin/AllQuests';
import AllChallenges from './pages/superadmin/AllChallenges';
import AddOcean from './pages/superadmin/AddOcean';
import EditOcean from './pages/superadmin/EditOcean';
import AddStage from './pages/superadmin/AddStage';
import EditStage from './pages/superadmin/EditStage';
import AddLesson from './pages/superadmin/AddLesson';
import EditLesson from './pages/superadmin/EditLesson';
import AddQuest from './pages/superadmin/AddQuest';
import EditQuest from './pages/superadmin/EditQuest';
import AddChallenge from './pages/superadmin/AddChallenge';
import EditChallenge from './pages/superadmin/EditChallenge';
import AdminQuestionAnswers from './pages/superadmin/AdminQuestionAnswers';
import AddQuestionAnswers from './pages/superadmin/AddQuestionAnswers';
import EditQuestionAnswers from './pages/superadmin/EditQuestionAnswers';
import AdminQuests from './pages/superadmin/AdminQuests';
import AdminChallenges from './pages/superadmin/AdminChallenges';
import AddQuestQuestionAnswers from './pages/superadmin/AddQuestQuestionAnswers';
import EditQuestQuestionAnswers from './pages/superadmin/EditQuestQuestionAnswers';
import AddChallengeQuestionAnswers from './pages/superadmin/AddChallengeQuestionAnswers';
import EditChallengeQuestionAnswers from './pages/superadmin/EditChallengeQuestionAnswers';
import UserDetails from './pages/superadmin/UserDetails';
import AddBadges from './pages/superadmin/AddBadges';
import Badges from './pages/superadmin/Badges';
import EditBadges from './pages/superadmin/EditBadges';
import AdminLeaderboard from './pages/superadmin/AdminLeaderboard';
import AllMiniQuiz from './pages/superadmin/AllMiniQuiz';
import AddMiniQuiz from './pages/superadmin/AddMiniQuiz';
import EditMiniQuiz from './pages/superadmin/EditMiniQuiz';
import AdminMiniQuiz from './pages/superadmin/AdminMiniQuiz';
import AddMiniQuizQuestionAnswers from './pages/superadmin/AddMiniQuizQuestionAnswers';
import EditMiniQuizQuestionAnswers from './pages/superadmin/EditMiniQuizQuestionAnswers';



function App() {

  const [isLoaderInfo, setIsLoaderInfo] = useRecoilState(isLoaderState);
  const [snackBarInfo, setSnackBarInfo] = useRecoilState(snakeBarState);


  return (

    <>
      {isLoaderInfo && <Loader />}


      <div className="App">
        <Router>
          <Routes>

            <Route element={<AuthRoutes />}>
              <Route exact index path={'/'} element={<Register />} />
              <Route exact path={'/login'} element={<Login />} />
              <Route exact path={'/register'} element={<Register />} />
              <Route exact path={'/forget-password'} element={<ForgotPassword />} />
              <Route exact path={'/register-form'} element={<RegisterForm />} />
            </Route>

            {/* Public Routes*/}
            <Route exact path={'/welcome'} element={<Welcome />} />
            <Route exact path={'/guest'} element={<Guest />} />
            <Route exact path='/stages/:stageId' element={<Stages />} />
            <Route exact path={'/verify'} element={<VerifyOTP />} />

            {/* Private Routes */}
            <Route element={<PrivateRoutes />}>
              <Route path={'/admin'} element={<Layout />}>
                <Route exact path='ocean' element={<Ocean />} />
                <Route exact path='challenge' element={<LeaderBoard />} />
                <Route exact path='quest' element={<Quest />} />
                <Route exact path='wallet' element={<Wallet />} />
                <Route exact path='coin-purchase' element={<CoinPurchase />} />
                <Route exact path='profile' element={<Profile />} />
                <Route exact path='edit-profile' element={<EditProfile />} />
              </Route>
            </Route>

            {/* super admin Routes */}
            <Route element={<SuperAdminRoutes />}>
              <Route path={'/super-admin'} element={<Layout />}>
                <Route exact path='dashboard' element={<SuperAdminDashboard />} />
                <Route exact path="admin-stages/:ocean_id" element={<AdminStages />} />
                <Route exact path="admin-quests/:quest_id" element={<AdminQuests />} />
                <Route exact path="admin-challenges/:challenge_id" element={<AdminChallenges />} />
                <Route exact path="admin-lessons/:stage_id" element={<AdminLessons />} />
                <Route exact path="all-stages" element={<AllStages />} />
                <Route exact path="all-lessons" element={<AllLessons />} />
                <Route exact path="all-quests" element={<AllQuests />} />
                <Route exact path="all-challenges" element={<AllChallenges />} />
                <Route exact path='users' element={<Users />} />
                <Route exact path='user-details/:userId' element={<UserDetails />} />
                <Route exact path='oceans' element={<Oceans />} />
                <Route exact path='add-ocean' element={<AddOcean />} />
                <Route exact path='edit-ocean/:ocean_id' element={<EditOcean />} />
                <Route exact path='add-stage' element={<AddStage />} />
                <Route exact path='edit-stage/:stage_id' element={<EditStage />} />
                <Route exact path='add-lesson' element={<AddLesson />} />
                <Route exact path='edit-lesson/:lesson_id' element={<EditLesson />} />
                <Route exact path='add-quest' element={<AddQuest />} />
                <Route exact path='edit-quest/:quest_id' element={<EditQuest />} />
                <Route exact path='add-challenge' element={<AddChallenge />} />
                <Route exact path='edit-challenge/:challenge_id' element={<EditChallenge />} />
                <Route exact path='view-question-answers/:lesson_id' element={<AdminQuestionAnswers />} />
                <Route exact path='add-question-answers' element={<AddQuestionAnswers />} />
                <Route exact path='edit-question-answers/:question_id' element={<EditQuestionAnswers />} />
                <Route exact path='add-quest-question-answers' element={<AddQuestQuestionAnswers />} />
                <Route exact path='edit-quest-question-answers/:question_id' element={<EditQuestQuestionAnswers />} />
                <Route exact path='add-challenge-question-answers' element={<AddChallengeQuestionAnswers />} />
                <Route exact path='edit-challenge-question-answers/:question_id' element={<EditChallengeQuestionAnswers />} />
                <Route exact path='add-badges' element={<AddBadges />} />
                <Route exact path='edit-badges/:badgeId' element={<EditBadges />} />
                <Route exact path='badges' element={<Badges />} />
                <Route exact path='admin-leaderboard' element={<AdminLeaderboard />} />
                <Route exact path='all-miniquiz' element={<AllMiniQuiz />} />
                <Route exact path='add-miniquiz' element={<AddMiniQuiz />} />
                <Route exact path='edit-miniquiz/:mini_quiz_id' element={<EditMiniQuiz />} />
                <Route exact path='admin-miniquiz/:mini_quiz_id' element={<AdminMiniQuiz />} />
                <Route exact path='add-miniquiz-question-answers' element={<AddMiniQuizQuestionAnswers />} />
                <Route exact path='edit-miniquiz-question-answers/:question_id' element={<EditMiniQuizQuestionAnswers />} />
              </Route>
            </Route>

            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </Router>
      </div>

      <SnackBar
        closeSnackPro={() => setSnackBarInfo({ snackStatus: false, snackColor: '', snackMsg: '' })}
        snackInfoPro={snackBarInfo}
      />

    </>
  );
}

export default App;
