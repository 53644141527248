import React, { useEffect, useState } from 'react';
import UsersYearBar from '../../components/charts/UsersYearBar';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { apiGetAuth } from '../../utils/Api';
import { ENDPOINTS } from '../../utils/EndPoint';
import OceanYearBar from '../../components/charts/OceanYearBar';
import QuestYearBar from '../../components/charts/QuestYearBar';
import ChallengeYearBar from '../../components/charts/ChallengeYearBar';

function SuperAdminDashboard() {
    const [userData, setUserData] = useState(null);

    const userCount = () => {
        apiGetAuth(
            `${ENDPOINTS.AdminUserCount}`,
            (res) => {                                
                setUserData(res);
            },
            (err) => {
                console.log("Error fetching user details", err);
            }
        );
    };

    useEffect(() => {
        userCount();
    }, []);

    return (
        <>
            <Container fluid className="mt-4">
                <Row>
                    {/* User Count Card */}
                    <Col lg={3} md={6} className="mb-4">
                        <Card>
                            <Card.Body>
                                <Card.Title>Users</Card.Title>
                                {userData ? (
                                    <p className="count-number">{userData.user_count}</p>
                                ) : (
                                    <p>Loading...</p>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>

                    {/* Ocean Count Card */}
                    <Col lg={3} md={6} className="mb-4">
                        <Card>
                            <Card.Body>
                                <Card.Title>Oceans</Card.Title>
                                {userData ? (
                                    <p className="count-number">{userData.ocean}</p>
                                ) : (
                                    <p>Loading...</p>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>

                    {/* Challenges Count Card */}
                    <Col lg={3} md={6} className="mb-4">
                        <Card>
                            <Card.Body>
                                <Card.Title>Challenges</Card.Title>
                                {userData ? (
                                    <p className="count-number">{userData.challenges}</p>
                                ) : (
                                    <p>Loading...</p>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>

                    {/* Quests Count Card */}
                    <Col lg={3} md={6} className="mb-4">
                        <Card>
                            <Card.Body>
                                <Card.Title>Quests</Card.Title>
                                {userData ? (
                                    <p className="count-number">{userData.quests}</p>
                                ) : (
                                    <p>Loading...</p>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col md={6} className="mb-4">
                        <Card>
                            <UsersYearBar />
                        </Card>
                    </Col>
                    <Col md={6} className="mb-4">
                        <Card>
                            <OceanYearBar />
                        </Card>
                    </Col>
                    <Col md={6} className="mb-4">
                        <Card>
                            <QuestYearBar />
                        </Card>
                    </Col>
                    <Col md={6} className="mb-4">
                        <Card>
                            <ChallengeYearBar />
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default SuperAdminDashboard;