import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import CustomTextField from '../../components/globals/CustomTextField';
import { apiGetAuth, apiPost } from '../../utils/Api';
import { ENDPOINTS } from '../../utils/EndPoint';
import { useNavigate, useParams } from 'react-router-dom';
import CustomSelectField from '../../components/globals/CustomSelectField';

const EditChallengeQuestionAnswers = () => {
    const methods = useForm({
        defaultValues: {
            questions: [{ question: '', answers: [{ text: '', is_correct: false }, { text: '', is_correct: false }, { text: '', is_correct: false }, { text: '', is_correct: false }] }],
            ocean_id: '',
            challenge_id: '',
        },
    });
    const { handleSubmit, reset, watch, setValue } = methods;
    const { question_id } = useParams();

    const [oceans, setOceans] = useState([]);
    const [filteredStages, setFilteredStages] = useState([]);
    const [filteredLessons, setFilteredLessons] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (question_id) {
            getQuestionDetailById(question_id);
        }
        getOceans();
    }, [question_id]);

    const onSubmit = (data) => {
        const formData = new FormData();
        formData.append('ocean_id', data.ocean_id);
        formData.append('challenge_id', data.challenge_id);

        data.questions.forEach((question, index) => {
            formData.append(`questions[${index}][question_id]`, question.question_id);
            formData.append(`questions[${index}][question]`, question.question);

            if (question.question_audio) {
                formData.append(`questions[${index}][question_audio]`, question.question_audio[0]);
            }

            question.answers.forEach((answer, answerIndex) => {
                formData.append(`questions[${index}][answers][${answerIndex}][answer_id]`, answer.answer_id);
                formData.append(`questions[${index}][answers][${answerIndex}][answer]`, answer.text);
                formData.append(`questions[${index}][answers][${answerIndex}][is_correct]`, answer.is_correct ? 1 : 0);

                if (answer.answer_audio) {
                    formData.append(`questions[${index}][answers][${answerIndex}][answer_audio]`, answer.answer_audio[0]);
                }
            });
        });

        apiPost(
            ENDPOINTS.AdminEditChallengeQuestionsAnswers,
            formData,
            (res) => {
                reset();
                navigate('/super-admin/all-challenges');
            },
            (err) => {
                console.error(err, "Error updating the data");
            },
        );
    };

    const getOceans = () => {
        apiGetAuth(
            ENDPOINTS.AdminOceansWithoutPaginated,
            (res) => {
                setOceans(res || []);
            },
            (err) => {
                console.error(err, "Error fetching oceans");
            }
        );
    };

    const getQuests = (oceanId) => {
        apiGetAuth(
            `${ENDPOINTS.AdminChallengesWithoutPaginated}?ocean_id=${oceanId}`,
            (res) => {
                setFilteredStages(res);
                setFilteredLessons([]);
            },
            (err) => {
                console.error(err, "Error fetching stages");
            }
        );
    };


    const getQuestionDetailById = (question_id) => {
        apiGetAuth(
            `${ENDPOINTS.AdminQuestionDetailByID}?question_id=${question_id}`,
            (res) => {
                const questionData = res;

                const answers = questionData.answers.map((answer) => ({
                    text: answer.answer,
                    is_correct: answer.is_correct,
                    answer_id: answer.id
                }));

                setValue("questions", [{
                    question: questionData.question,
                    question_id: questionData.id,
                    answers: answers
                }]);
                setValue("ocean_id", questionData.ocean_id);
                setValue("challenge_id", questionData.challenge_id);
                setValue("question", questionData.question);
                getQuests(questionData.challenge_id)
            },
            (err) => {
                console.error(err, "Error fetching lesson questions");
            }
        );
    };

    const handleOceanChange = (selectedOption) => {
        if (selectedOption?.value) {
            setValue("ocean_id", selectedOption.value);
            setValue("challenge_id", "");
            getQuests(selectedOption.value);
        }
    };

    const handleQuestChange = (selectedOption) => {
        if (selectedOption?.value) {
            setValue("challenge_id", selectedOption.value);
        }
    };

    return (
        <div className="container d-flex justify-content-center align-items-center min-vh-100">
            <div className="form-wrapper bg-light p-4 rounded shadow-sm">
                <h2 className="text-center mb-4">Update Question & Answers</h2>

                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>

                        {/* Ocean Dropdown */}
                        <CustomSelectField
                            label="Select Ocean"
                            name="ocean_id"
                            options={oceans.map(ocean => ({ value: ocean.id, label: ocean.name }))}
                            classNamePro="ocean-field mb-3"
                            validation={{ required: 'This field is required' }}
                            onChange={(e) => {
                                const selectedOption = {
                                    value: e.target.value,
                                    label: oceans.find(ocean => ocean.id === e.target.value)?.name
                                };
                                handleOceanChange(selectedOption);
                            }}
                        />

                        {/* Stage Dropdown */}
                        <CustomSelectField
                            label="Select Quest"
                            name="challenge_id"
                            options={filteredStages.map(stage => ({ value: stage.id, label: stage.title }))}
                            classNamePro="ocean-field mb-3"
                            validation={{ required: 'This field is required' }}
                            onChange={(e) => {
                                const selectedOption = {
                                    value: e.target.value,
                                    label: filteredStages.find(stage => stage.id === e.target.value)?.title
                                };
                                handleQuestChange(selectedOption);
                            }}
                        />

                        {/* Questions Section */}
                        <div className="mb-3">
                            <CustomTextField
                                label="Question"
                                placeholder="Enter the Question"
                                name="question"
                                type="text"
                                classNamePro="ocean-field mb-3"
                                isRequired={true}
                                validation={{ required: "Question is required" }}
                            />
                            {watch("questions")?.map((question, index) => (
                                <div key={index} className="mb-3">
                                    <CustomTextField
                                        label="Question"
                                        placeholder="Enter the Question"
                                        name={`questions[${index}].question`}
                                        type="text"
                                        classNamePro="ocean-field mb-3"
                                        isRequired={true}
                                        validation={{ required: "Question is required" }}
                                    />
                                    <CustomTextField
                                        label="Question Audio"
                                        name={`questions[${index}].question_audio`}
                                        type="file"
                                        accept="audio/*"
                                        classNamePro="ocean-field mb-3"
                                        isRequired={false}
                                    />

                                    {/* Answers Section */}
                                    {question.answers?.map((answer, answerIndex) => (
                                        <div key={answerIndex} className="d-flex align-items-center mb-2">
                                            <CustomTextField
                                                label={`Answer ${answerIndex + 1}`}
                                                placeholder={`Enter Answer ${answerIndex + 1}`}
                                                name={`questions[${index}].answers[${answerIndex}].text`}
                                                type="text"
                                                classNamePro="ocean-field me-2"
                                                isRequired={true}
                                                validation={{ required: "Answer is required" }}
                                            />

                                            <div className="d-flex align-items-center custom-text-field ocean-field mt-4">

                                                <input
                                                    type="radio"
                                                    name={`questions[${index}].is_correct`}
                                                    value={answerIndex}
                                                    checked={watch(`questions[${index}].answers[${answerIndex}].is_correct`)}
                                                    onChange={() => {
                                                        question.answers.forEach((_, i) => {
                                                            setValue(`questions[${index}].answers[${i}].is_correct`, i === answerIndex);
                                                        });
                                                    }}
                                                />
                                                <label className="ms-2">Correct</label>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>

                        <div className="text-center">
                            <button type="submit" className="btn btn-primary w-100">
                                Update
                            </button>
                        </div>
                    </form>
                </FormProvider>
            </div>
        </div>
    );
};

export default EditChallengeQuestionAnswers;