import React, { useState } from 'react';
import TableComponent from '../../components/globals/TableComponent';
import { ENDPOINTS } from '../../utils/EndPoint';
import { useNavigate } from 'react-router-dom';
import { apiPost } from '../../utils/Api';
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa';


function AllLessons() {

  const navigate = useNavigate();
  const [oceans, setStages] = useState([]);

  const handleOnClick = async (lesson_id, ocean_id, stage_id) => {
    navigate(`/super-admin/view-question-answers/${lesson_id}`, {
      state: { ocean_id: ocean_id, stage_id: stage_id, lesson_id: lesson_id }
    });
  };

  const deleteStage = (lessonId) => {

    const payload = {
      id: lessonId,
    }
    apiPost(
      ENDPOINTS.AdminDeleteStage,
      payload,
      (res) => {
        setStages(res?.data);
        window.location.reload();
      },
      (err) => {
        console.log(err, 'Error deleting lesson');
      }
    )
  }

  const handleAddClick = () => {
    navigate('/super-admin/add-lesson');
  };

  const handleEditClick = async (lesson_id) => {
    navigate(`/super-admin/edit-lesson/${lesson_id}`);
  };

  const deleteLesson = (lessonId) => {

    const payload = {
      id: lessonId,
    }
    apiPost(
      ENDPOINTS.AdminDeleteLesson,
      payload,
      (res) => {
        setStages(res?.data);
        window.location.reload();
      },
      (err) => {
        console.log(err, 'Error deleting ocean');
      }
    )
  }

  const columns = [
    {
      name: 'ID',
      selector: (row) => row.id,
      sortable: true,
      width: '60px',
    },
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'Number Of Questions',
      selector: (row) => row.total_questions,
      sortable: true,
    },
    {
      name: 'Stage Name',
      selector: (row) => row.stage?.name,
      sortable: true,
    },
    {
      name: 'Ocean Name',
      selector: (row) => row.ocean?.name,
      sortable: true,
    },
    {
      name: 'Description',
      selector: (row) => (row.description.length > 250 ? row.description.replace(/<[^>]*>/g, '').substring(0, 250) + '...' : row.description.replace(/<[^>]*>/g, '')),
      sortable: false,
    },
    {
      name: 'Actions',
      selector: (row) => (
        <div className='set-show-all'>
          <FaEye
            className="icon me-2"
            onClick={() => handleOnClick(row.id, row.ocean_id, row.stage_id)}
            title="View"
          />
          <FaEdit
            className="icon me-2"
            onClick={() => handleEditClick(row.id)}
            title="Edit"
          />
          <FaTrash
            className="icon text-danger"
            onClick={() => {
              if (window.confirm("This lesson will be removed from all associated questions and badges. Please confirm if you want to delete it.")) {
                deleteLesson(row.id);
              }
            }}
            title="Delete"
          />
        </div>
      ),
      sortable: false,
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3 mt-3 me-2">
        <h1 className='ms-2'>Lessons</h1>
        <button
          className="btn btn-primary"
          onClick={handleAddClick}
        >
          Add Lessons
        </button>
      </div>
      <TableComponent
        title={"Lessons"}
        endPoint={ENDPOINTS.AdminLessonsPaginated}
        headers={columns}
      />
    </>

  );
}

export default AllLessons;