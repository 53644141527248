import React, { useEffect, useState } from 'react';
import { ENDPOINTS } from '../../utils/EndPoint';
import { apiGetAuth } from '../../utils/Api';
import { useNavigate } from 'react-router-dom';

function Badges() {
  const [activeTab, setActiveTab] = useState('ocean');

  const [badges, setBadges] = useState({
    ocean_badges: [],
    quiz_badges: [],
    challenge_quest_badges: [],
    streak_badges: [],
    quest_badges: [],
  });

  const navigate = useNavigate();


  const getBadges = async () => {
    apiGetAuth(
      ENDPOINTS.AdminBadges,
      (res) => {
        setBadges(res);
      },
      (err) => {
        console.log("Error:", err);
      }
    );
  };

  useEffect(() => {
    getBadges();
  }, []);

  const handleDelete = (badgeId) => {
    apiGetAuth(
      `${ENDPOINTS.AdminDeleteBadge}/${badgeId}`,
      (res) => {
        setBadges((prevBadges) => ({
          ...prevBadges,
          ocean_badges: prevBadges.ocean_badges.filter(badge => badge.id !== badgeId),
          quiz_badges: prevBadges.quiz_badges.filter(badge => badge.id !== badgeId),
          challenge_quest_badges: prevBadges.challenge_quest_badges.filter(badge => badge.id !== badgeId),
          streak_badges: prevBadges.streak_badges.filter(badge => badge.id !== badgeId),
          quest_badges: prevBadges.quest_badges.filter(badge => badge.id !== badgeId),
        }));
      },
      (err) => {
        console.log(err, 'Error deleting ocean');
      }
    );
  }

  // const handleAdd = () => {
  //   navigate("/super-admin/add-badges")
  // }

  const handleEdit = (id) => {
    navigate(`/super-admin/edit-badges/${id}`);
  };

  return (
    <div className='mb-4 mt-2 px-4'>
      <div className='d-flex justify-content-between'>
        <h1>Badges</h1>
        {/* <button className="btn btn-primary me-2 pb-0 pt-0" onClick={() => handleAdd()}>Add Badge</button> */}
      </div>
      <div>
        {/* Tab Navigation */}
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === 'ocean' ? 'active' : ''}`}
              onClick={() => setActiveTab('ocean')}
            >
              Ocean Badges
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === 'quiz' ? 'active' : ''}`}
              onClick={() => setActiveTab('quiz')}
            >
              Quiz Badges
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === 'challenge_quest' ? 'active' : ''}`}
              onClick={() => setActiveTab('challenge_quest')}
            >
              Challenge Quest
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === 'streak' ? 'active' : ''}`}
              onClick={() => setActiveTab('streak')}
            >
              Streak Badges
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === 'quest' ? 'active' : ''}`}
              onClick={() => setActiveTab('quest')}
            >
              Quest Badges
            </button>
          </li>
        </ul>

        {/* Tab Content */}
        <div className="tab-content mt-3">
          {activeTab === 'ocean' && (
            <div className="tab-pane active">
              {badges.ocean_badges.length ? (
                <div className="row">
                  {badges.ocean_badges.map((badge) => (
                    <div key={badge.id} className="col-md-3 mb-4">
                      <div className="badge-card shadow p-3">
                        <img src={`${process.env.REACT_APP_BASE_URL}/${badge.avatar}`} alt={badge.goal_name} className="badge-img" />
                        <h5 className="badge-title">{badge.goal_name}</h5>
                        <p className="badge-description">{badge.description.replace(/<[^>]*>/g, '')}</p>
                        <p>Number Of Stages: {badge.number_of_stages}</p>
                        <p className="badge-reward">Coins Reward: {badge.coins_reward} coins</p>
                        <div className="badge-actions">
                          <button className="btn btn-primary me-2" onClick={() => handleEdit(badge.id)}>Edit</button>
                          <button className="btn btn-danger" onClick={() => {
                            if (window.confirm("This badge will be removed from all user profiles where it has been earned. Please confirm if you want to delete it.")) {
                              handleDelete(badge.id);
                            }
                          }}
                          >Delete</button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p>No Ocean Badges Available</p>
              )}
            </div>
          )}

          {activeTab === 'quiz' && (
            <div className="tab-pane active">
              {badges.quiz_badges.length ? (
                <div className="row">
                  {badges.quiz_badges.map((badge) => (
                    <div key={badge.id} className="col-md-3 mb-4">
                      <div className="badge-card shadow p-3">
                        <img src={`${process.env.REACT_APP_BASE_URL}/${badge.avatar}`} alt={badge.goal_name} className="badge-img" />
                        <h5 className="badge-title">{badge.goal_name}</h5>
                        <p className="badge-description">{badge.description.replace(/<[^>]*>/g, '')}</p>
                        <p>Number Of Oceans: {badge.number_of_oceans}</p>
                        <p className="badge-reward">Coins Reward: {badge.coins_reward} coins</p>
                        <div className="badge-actions">
                          <button className="btn btn-primary me-2" onClick={() => handleEdit(badge.id)}>Edit</button>
                          <button className="btn btn-danger" onClick={() => {
                            if (window.confirm("This badge will be removed from all user profiles where it has been earned. Please confirm if you want to delete it.")) {
                              handleDelete(badge.id);
                            }
                          }}
                          >Delete</button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p>No Quiz Badges Available</p>
              )}
            </div>
          )}

          {/* Challenge Quest Badges */}
          {activeTab === 'challenge_quest' && badges.challenge_quest_badges && badges.challenge_quest_badges.length > 0 && (
            <div className="tab-pane active">
              <div className="row">
                {badges.challenge_quest_badges.map(badge => (
                  <div key={badge.id} className="col-md-3 mb-4">
                    <div className="badge-card shadow p-3">
                      <img src={`${process.env.REACT_APP_BASE_URL}/${badge.avatar}`} alt={badge.goal_name} className="badge-img" />
                      <h5 className="badge-title">{badge.goal_name}</h5>
                      <p className="badge-description">{badge.description.replace(/<[^>]*>/g, '')}</p>
                      <p>Challenge Points: {badge.challenge_points}</p>
                      <p className="badge-reward">Coins Reward: {badge.coins_reward}</p>
                      <div className="badge-actions">
                        <button className="btn btn-primary me-2" onClick={() => handleEdit(badge.id)}>Edit</button>
                        <button className="btn btn-danger" onClick={() => {
                          if (window.confirm("This badge will be removed from all user profiles where it has been earned. Please confirm if you want to delete it.")) {
                            handleDelete(badge.id);
                          }
                        }}
                        >Delete</button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {activeTab === 'streak' && (
            <div className="tab-pane active">
              {badges.streak_badges.length ? (
                <div className="row">
                  {badges.streak_badges.map((badge) => (
                    <div key={badge.id} className="col-md-3 mb-4">
                      <div className="badge-card shadow p-3">
                        <img src={`${process.env.REACT_APP_BASE_URL}/${badge.avatar}`} alt={badge.goal_name} className="badge-img" />
                        <h5 className="badge-title">{badge.goal_name}</h5>
                        <p className="badge-description">{badge.description.replace(/<[^>]*>/g, '')}</p>
                        <p>Number Of Days: {badge.number_of_days}</p>
                        <p className="badge-reward">Coins Reward: {badge.coins_reward} coins</p>
                        <div className="badge-actions">
                          <button className="btn btn-primary me-2" onClick={() => handleEdit(badge.id)}>Edit</button>
                          <button className="btn btn-danger" onClick={() => {
                            if (window.confirm("This badge will be removed from all user profiles where it has been earned. Please confirm if you want to delete it.")) {
                              handleDelete(badge.id);
                            }
                          }}
                          >Delete</button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p>No Streak Badges Available</p>
              )}
            </div>
          )}

          {activeTab === 'quest' && (
            <div className="tab-pane active">
              {badges.quest_badges.length ? (
                <div className="row">
                  {badges.quest_badges.map((badge) => (
                    <div key={badge.id} className="col-md-3 mb-4">
                      <div className="badge-card shadow p-3">
                        <img src={`${process.env.REACT_APP_BASE_URL}/${badge.avatar}`} alt={badge.goal_name} className="badge-img" />
                        <h5 className="badge-title">{badge.goal_name}</h5>
                        <p className="badge-description">{badge.description.replace(/<[^>]*>/g, '')}</p>
                        <p>Number Of Quests: {badge.number_of_quests}</p>
                        <p className="badge-reward">Reward: {badge.coins_reward} coins</p>
                        <div className="badge-actions">
                          <button className="btn btn-primary me-2" onClick={() => handleEdit(badge.id)}>Edit</button>
                          <button className="btn btn-danger" onClick={() => {
                            if (window.confirm("This badge will be removed from all user profiles where it has been earned. Please confirm if you want to delete it.")) {
                              handleDelete(badge.id);
                            }
                          }}
                          >Delete</button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p>No Quest Badges Available</p>
              )}
            </div>
          )}
        </div>

      </div>
    </div>
  );
}

export default Badges;
