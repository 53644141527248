import React, { useEffect, useState } from 'react';
import {
    FaUser,
} from 'react-icons/fa';
import { GiArrowScope, GiWaveSurfer } from "react-icons/gi";
import { Menu, MenuItem, Sidebar } from 'react-pro-sidebar';
import './Sidebar.css';
import { BiSolidNotepad } from 'react-icons/bi';
import { IoLogOut, IoWallet } from 'react-icons/io5';
import { TbCoinFilled } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo.gif';
import { Button, Modal } from 'react-bootstrap';
import { CgDanger } from 'react-icons/cg';
import { burgerShowState } from '../../store/RecoilStore';
import { useRecoilState } from 'recoil';
import { AiOutlineDashboard } from "react-icons/ai";
import { BsPostage } from 'react-icons/bs';
import { LuBadgeCheck } from "react-icons/lu";
import { MdLeaderboard, MdOutlinePlayLesson, MdOutlineQuiz } from 'react-icons/md';




const SidebarComponent = ({ }) => {

    const [burgerShow, setBurgerShow] = useState(false);
    const [urlParam, setUrlParam] = useState(window.location.pathname.split('/').pop(),);
    const [modalShow, setModalShow] = useState(false);
    const navigate = useNavigate();

    // recoil
    const [burgerShowInfo, setBurgerShowInfo] = useRecoilState(burgerShowState);


    const logOut = () => {
        localStorage.clear();
        window.location.href = "/";
    }


    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const updateWindowWidth = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', updateWindowWidth);
        return () => {
            window.removeEventListener('resize', updateWindowWidth);
        };
    }, []);

    const userRole = localStorage.getItem('role');

    return (
        <>
            {burgerShowInfo && (
                <Sidebar
                    className="sidebar-dashboard"
                    backgroundColor="#00204E"
                    collapsed={burgerShow}
                >
                    <div className="sidebar-dashboard-logo d-flex align-items-center justify-content-center">
                        <div className='d-flex align-items-center'>
                            <img src={logo} className='logo' alt="Logo" />
                            <h4 className='text-warning mb-0 skranji-bold'>Shark Skillz </h4>
                        </div>
                    </div>

                    {userRole === 'user' && (
                        <Menu iconShape="square">

                            <hr className='custom-hr' />

                            <MenuItem
                                active={urlParam === 'ocean' ? true : false}
                                onClick={() => {
                                    setUrlParam('ocean');
                                    navigate(`/admin/ocean`);
                                    if (windowWidth <= 992) {
                                        setBurgerShowInfo(!burgerShowInfo)
                                    }
                                }}
                                icon={<GiWaveSurfer />}
                                className="sidebar-menu-design sidebar-menu-design-custom skranji-regular"
                            >
                                Ocean
                            </MenuItem>

                            <MenuItem
                                active={urlParam === 'challenge' ? true : false}
                                onClick={() => {
                                    setUrlParam('challenge');
                                    navigate(`/admin/challenge`);
                                    if (windowWidth <= 992) {
                                        setBurgerShowInfo(!burgerShowInfo)
                                    }
                                }}
                                icon={<GiArrowScope />}
                                className="sidebar-menu-design sidebar-menu-design-custom skranji-regular"
                            >
                                Challenge
                            </MenuItem>

                            <MenuItem
                                active={urlParam === 'quest' ? true : false}
                                onClick={() => {
                                    setUrlParam('quest');
                                    navigate(`/admin/quest`);
                                    if (windowWidth <= 992) {
                                        setBurgerShowInfo(!burgerShowInfo)
                                    }
                                }}
                                icon={<BiSolidNotepad />}
                                className="sidebar-menu-design sidebar-menu-design-custom skranji-regular"
                            >
                                Quest
                            </MenuItem>

                            <MenuItem
                                active={urlParam === 'wallet' ? true : false}
                                onClick={() => {
                                    setUrlParam('wallet');
                                    navigate(`/admin/wallet`);
                                    if (windowWidth <= 992) {
                                        setBurgerShowInfo(!burgerShowInfo)
                                    }

                                }}
                                icon={<IoWallet />}
                                className="sidebar-menu-design sidebar-menu-design-custom skranji-regular"
                            >
                                Wallet
                            </MenuItem>

                            <MenuItem
                                active={urlParam === 'coin-purchase' ? true : false}
                                onClick={() => {
                                    setUrlParam('coin-purchase');
                                    navigate(`/admin/coin-purchase`);
                                    if (windowWidth <= 992) {
                                        setBurgerShowInfo(!burgerShowInfo)
                                    }
                                }}
                                icon={<TbCoinFilled />}
                                className="sidebar-menu-design sidebar-menu-design-custom skranji-regular"
                            >
                                Coin purchase
                            </MenuItem>


                            <div>
                                <p className='custom-side-space text-white'>ACCOUNT PAGES</p>
                                <MenuItem
                                    active={urlParam === 'profile' ? true : false}
                                    onClick={() => {
                                        setUrlParam('profile');
                                        navigate(`/admin/profile`);
                                        if (windowWidth <= 992) {
                                            setBurgerShowInfo(!burgerShowInfo)
                                        }
                                    }}
                                    icon={<FaUser />}
                                    className="sidebar-menu-design sidebar-menu-design-custom skranji-regular"
                                >
                                    Profile
                                </MenuItem>
                            </div>

                            <hr className='custom-hr' />

                            <MenuItem
                                onClick={() => setModalShow(true)}
                                icon={<IoLogOut />}
                                className="sidebar-menu-design sidebar-menu-design-custom skranji-regular"
                            >
                                Logout
                            </MenuItem>

                        </Menu>
                    )}

                    {userRole === 'admin' && (
                        <Menu iconShape="square">

                            <hr className='custom-hr' />

                            <MenuItem
                                active={urlParam === 'dashboard' ? true : false}
                                onClick={() => {
                                    setUrlParam('dashboard');
                                    navigate(`/super-admin/dashboard`);
                                    if (windowWidth <= 992) {
                                        setBurgerShowInfo(!burgerShowInfo)
                                    }
                                }}
                                icon={<AiOutlineDashboard   />}
                                className="sidebar-menu-design sidebar-menu-design-custom skranji-regular"
                            >
                                Dashboard
                            </MenuItem>

                            <MenuItem
                                active={urlParam === 'users' ? true : false}
                                onClick={() => {
                                    setUrlParam('users');
                                    navigate(`/super-admin/users`);
                                    if (windowWidth <= 992) {
                                        setBurgerShowInfo(!burgerShowInfo)
                                    }
                                }}
                                icon={<FaUser />}
                                className="sidebar-menu-design sidebar-menu-design-custom skranji-regular"
                            >
                                Users
                            </MenuItem>

                            <MenuItem
                                active={urlParam === 'oceans' ? true : false}
                                onClick={() => {
                                    setUrlParam('oceans');
                                    navigate(`/super-admin/oceans`);
                                    if (windowWidth <= 992) {
                                        setBurgerShowInfo(!burgerShowInfo)
                                    }
                                }}
                                icon={<GiWaveSurfer />}
                                className="sidebar-menu-design sidebar-menu-design-custom skranji-regular"
                            >
                                Oceans
                            </MenuItem>

                            <MenuItem
                                active={urlParam === 'all-stages' ? true : false}
                                onClick={() => {
                                    setUrlParam('all-stages');
                                    navigate(`/super-admin/all-stages`);
                                    if (windowWidth <= 992) {
                                        setBurgerShowInfo(!burgerShowInfo)
                                    }
                                }}
                                icon={<BsPostage  />}
                                className="sidebar-menu-design sidebar-menu-design-custom skranji-regular"
                            >
                                Stages
                            </MenuItem>

                            <MenuItem
                                active={urlParam === 'all-lessons' ? true : false}
                                onClick={() => {
                                    setUrlParam('all-lessons');
                                    navigate(`/super-admin/all-lessons`);
                                    if (windowWidth <= 992) {
                                        setBurgerShowInfo(!burgerShowInfo)
                                    }
                                }}
                                icon={<MdOutlinePlayLesson  />}
                                className="sidebar-menu-design sidebar-menu-design-custom skranji-regular"
                            >
                                Lessons
                            </MenuItem>
                            
                            <MenuItem
                                active={urlParam === 'all-quests' ? true : false}
                                onClick={() => {
                                    setUrlParam('all-quests');
                                    navigate(`/super-admin/all-quests`);
                                    if (windowWidth <= 992) {
                                        setBurgerShowInfo(!burgerShowInfo)
                                    }
                                }}
                                icon={<BiSolidNotepad />}
                                className="sidebar-menu-design sidebar-menu-design-custom skranji-regular"
                            >
                                Quests
                            </MenuItem>

                            <MenuItem
                                active={urlParam === 'all-challenges' ? true : false}
                                onClick={() => {
                                    setUrlParam('all-challenges');
                                    navigate(`/super-admin/all-challenges`);
                                    if (windowWidth <= 992) {
                                        setBurgerShowInfo(!burgerShowInfo)
                                    }
                                }}
                                icon={<GiArrowScope />}
                                className="sidebar-menu-design sidebar-menu-design-custom skranji-regular"
                            >
                                Challenges
                            </MenuItem>

                            <MenuItem
                                active={urlParam === 'badges' ? true : false}
                                onClick={() => {
                                    setUrlParam('badges');
                                    navigate(`/super-admin/badges`);
                                    if (windowWidth <= 992) {
                                        setBurgerShowInfo(!burgerShowInfo)
                                    }
                                }}
                                icon={<LuBadgeCheck   />}
                                className="sidebar-menu-design sidebar-menu-design-custom skranji-regular"
                            >
                                Badges
                            </MenuItem>

                            <MenuItem
                                active={urlParam === 'all-miniquiz' ? true : false}
                                onClick={() => {
                                    setUrlParam('all-miniquiz');
                                    navigate(`/super-admin/all-miniquiz`);
                                    if (windowWidth <= 992) {
                                        setBurgerShowInfo(!burgerShowInfo)
                                    }
                                }}
                                icon={<MdOutlineQuiz    />}
                                className="sidebar-menu-design sidebar-menu-design-custom skranji-regular"
                            >
                                Mini Quiz
                            </MenuItem>

                            <MenuItem
                                active={urlParam === 'admin-leaderboard' ? true : false}
                                onClick={() => {
                                    setUrlParam('admin-leaderboard');
                                    navigate(`/super-admin/admin-leaderboard`);
                                    if (windowWidth <= 992) {
                                        setBurgerShowInfo(!burgerShowInfo)
                                    }
                                }}
                                icon={<MdLeaderboard    />}
                                className="sidebar-menu-design sidebar-menu-design-custom skranji-regular"
                            >
                                Leaderboard
                            </MenuItem>

                            <hr className='custom-hr' />

                            <MenuItem
                                onClick={() => setModalShow(true)}
                                icon={<IoLogOut />}
                                className="sidebar-menu-design sidebar-menu-design-custom skranji-regular"
                            >
                                Logout
                            </MenuItem>

                        </Menu>
                    )}
                </Sidebar>
            )}



            <Modal show={modalShow} onHide={() => setModalShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Logout</Modal.Title>
                </Modal.Header>
                <Modal.Body> <div className="d-flex gap-2"><h4 className="mb-0 text-danger"><CgDanger /> </h4> <h6 className="my-auto">
                    Are you sure you want to logout ?
                </h6></div> </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setModalShow(false)}>
                        No
                    </Button>
                    <Button variant="primary" onClick={() => logOut()}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal >

        </>
    );
};

export default SidebarComponent;